import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

import checkEmptyString from 'helpers/check-empty-string'
import { hideModal, showModal } from 'helpers/modal'
import {
  createPerk as createPerkService,
  getPerk as getPerkService,
  updatePerk as updatePerkService,
} from 'services/perks'
import { uploadFile } from 'services/files'

import Form from 'components/Form'
import FormGroup from 'components/FormGroup'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import TextArea from 'components/TextArea'
import CutImage from 'components/CutImage'

import { Container, ContentThumbnail, ThumbnailUploadContainer, FormButtons } from './style'
import { AiOutlineCamera } from 'react-icons/ai'

interface CreateAndEditPerkProps {
  perkId: string
}

const CreateAndEditCategory: React.FC = () => {
  const { perkId } = useParams<CreateAndEditPerkProps>()

  const history = useHistory()

  const [description, setDescription] = useState('')
  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
  const [thumbnail, setThumbnail] = useState('')

  const createPerk = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válida para o conteúdo.')
      }

      if (!thumbnailFileToUpload) {
        throw new Error('Selecione uma imagem de miniatura.')
      }

      const imageReference = await getImageReference(thumbnailFileToUpload)

      await createPerkService({
        description,
        file: imageReference,
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo criado com sucesso!',
        icon: 'success',
      })

      goToPerks()
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o conteúdo. ' + error.message,
        icon: 'error',
      })
    }
  }

  const updatePerk = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válida para o conteúdo.')
      }

      if (!thumbnail && !thumbnailFileToUpload) {
        throw new Error('Selecione uma imagem de miniatura.')
      }

      const resolvePerkFile = async (): Promise<string> => {
        if (thumbnailFileToUpload) return await getImageReference(thumbnailFileToUpload)
        return thumbnail
      }

      await updatePerkService(perkId, {
        description,
        file: await resolvePerkFile(),
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo editado com sucesso!',
        icon: 'success',
      })

      goToPerks()
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o conteúdo. ' + error.message,
        icon: 'error',
      })
    }
  }

  const selectThumbnail = (): void => {
    showModal('Selecionar Imagem de Miniatura', <CutImage aspect={1.1} onCutImage={onCutImage} />)
  }

  const onCutImage = (file: File): void => {
    if (file) {
      setThumbnailFileToUpload(file)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setThumbnail(`${reader.result}`)

      hideModal()
    }
  }

  const getImageReference = async (imageFile: File): Promise<string> => {
    try {
      return (await uploadFile(imageFile)).reference
    } catch {
      throw new Error(
        'Erro ao fazer upload da imagem de miniatura. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
      )
    }
  }

  const goToPerks = (): void => {
    history.push('/perks')
  }

  const getPerk = useCallback(async () => {
    if (perkId) {
      const perk = await getPerkService(perkId)
      if (perk && Object.keys(perk).length) {
        setDescription(perk.description)
        setThumbnail(perk.file)
      }
    }
  }, [perkId])

  useEffect(() => {
    getPerk()
  }, [getPerk])

  const isEditting = useMemo(() => {
    if (perkId) {
      return true
    }

    return false
  }, [perkId])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <Link key={2} to="/perks">
            Conteúdos Landing Page
          </Link>,
          <span key={3}>{isEditting ? 'Editar' : 'Criar'} Conteúdo Landing Page</span>,
        ]}
      />

      <PageTitle>{isEditting ? 'Editar' : 'Criar'} Conteúdo Landing Page</PageTitle>

      <Form>
        <FormGroup>
          <label htmlFor="description" className="required">
            Descrição
          </label>
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
            required
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="thumbnail" className="required">
            Imagem
          </label>

          <ThumbnailUploadContainer>
            {!thumbnail && (
              <Button type="button" onClick={selectThumbnail}>
                Selecionar Imagem
              </Button>
            )}

            {thumbnail && (
              <ContentThumbnail>
                <div>
                  <button type="button" onClick={selectThumbnail}>
                    <AiOutlineCamera />
                    <span>Trocar Imagem</span>
                  </button>
                </div>
                <img src={thumbnail} alt="" />
              </ContentThumbnail>
            )}
          </ThumbnailUploadContainer>
        </FormGroup>

        <FormButtons>
          <Button type="button" className="danger" onClick={goToPerks}>
            Cancelar
          </Button>
          <Button onClick={(e) => (isEditting ? updatePerk(e) : createPerk(e))} className="success">
            Salvar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  )
}

export default CreateAndEditCategory
