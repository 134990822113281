import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Link } from 'react-router-dom'
import Switch from 'react-switch'
import { AiOutlineSearch } from 'react-icons/ai'
import Pagination from '../../components/Pagination'
import DefaultButton from '../../components/Button'
import DefaultInput from '../../components/Input'
import User from 'models/user'
import {
  deleteUserById,
  getUsers as getUsersService,
  toAdmin,
  updateStatusUser,
  updateUserPlan,
} from 'services/users'

import BreadCrumb from 'components/BreadCrumb'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'

import {
  Container,
  CardContainer,
  Card,
  Actions,
  UserName,
  Label,
  Buttons,
  Date,
  UserButton,
} from './style'
import isAdmin from 'helpers/is-admin'
import Swal from 'sweetalert2'
import { FaDownload, FaFileCsv } from 'react-icons/fa'
import { hideModal, showModal } from 'helpers/modal'
import FormGroup from 'components/FormGroup'
import Select from 'components/Select'
import Plan from 'models/plan'
import DatePicker from 'react-datepicker'
import { FormButtons } from 'pages/Categories/CreateAndEdit/style'
import Button from '../../components/Button'
import { useHistory } from 'react-router'
import moment from 'moment'
import { BiTrash } from 'react-icons/bi'
const itensPerPage = 20

const Users: React.FC = () => {
  const [users, setUsers] = useState([] as User[])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [allUsers, setAllUsers] = useState([] as User[])
  const [selectedPlans, setSelectedPlans] = useState<{ label: string; value: string }[]>([])
  const [finishDate, setFinishDate] = useState<any>()
  const history = useHistory()

  const headers = [
    { label: 'Nome', key: 'name' },
    { label: 'E-mail', key: 'email' },
    { label: 'Plano', key: 'planStr' },
  ]

  const getUsers = useCallback(
    async (filters) => {
      if (search) {
        filters.search = search
      }

      if (filters.offset === 0) {
        delete filters.offset
      }

      const allUsers = await getUsersService(filters)
      if (allUsers) {
        const data = allUsers.users.map((user) => ({
          ...user,
          isAdmin: isAdmin(user.roles),
          planStr:
            !user.plan || !user.plan.length
              ? 'FREE'
              : !user.plan[0]
              ? 'DESCONHECIDO'
              : user.plan[0].title,
        }))
        setUsers(data)
        setTotal(allUsers.total)
      }
    },
    [search]
  )

  const updatePermission = async (user: User): Promise<void> => {
    await toAdmin(user.user_id)

    Swal.fire({
      title: 'Sucesso',
      text: 'Permissão alterada com sucesso',
      icon: 'success',
    })

    setUsers(
      users.map((item) => {
        if (item.user_id === user.user_id) {
          item.isAdmin = !user.isAdmin
        }

        return item
      })
    )
  }

  const updateStatus = async (user: User): Promise<void> => {
    Swal.fire({
      title: '<strong>Atenção!</strong>',
      html: user.is_active
        ? 'Tem certeza que deseja inativar este usuário? Ao realizar a inativação seu plano será cancelado.'
        : 'Tem certeza que deseja ativar este usuário?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateStatusUser(user.user_id, !user.is_active)

          Swal.fire({
            title: 'Sucesso',
            text: 'Alteração realizada com sucesso',
            icon: 'success',
          })

          setUsers(
            users.map((item) => {
              if (item.user_id === user.user_id) {
                item.is_active = !user.is_active
              }

              return item
            })
          )
        } catch (error) {
          console.error(error)
        }
      }
    })
  }

  const deleteUser = async (user: User): Promise<void> => {
    Swal.fire({
      title: '<strong>Atenção!</strong>',
      html: 'Essa operação não poderá ser desfeita. Você tem certeza que deseja continuar?',
      showCancelButton: true,
      icon: 'warning',
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteUserById(user.user_id)
          Swal.fire({
            title: 'Sucesso',
            text: 'Usuário excluído com sucesso.',
            icon: 'success',
          })
          await getUsers({})
        } catch (error) {
          console.error(error)
        }
      }
    })
  }

  const usersToBeShown = useMemo(() => {
    return users && users.length
      ? users.map((user) => ({
          name: user.name,
          email: user.email,
          plan:
            !user.plan || !user.plan.length
              ? 'FREE'
              : !user.plan[0]
              ? 'DESCONHECIDO'
              : user.plan[0].title,
          isAdmin: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
              title={
                user.info.login_by
                  ? 'Usuário criado com conta ' + user.info.login_by + ' não pode ser Administrador'
                  : user.isAdmin
                  ? 'Remover permissões'
                  : 'Tornar Administrador'
              }
            >
              <Switch
                disabled={!!user.info.login_by}
                onChange={() => updatePermission(user)}
                checked={user.isAdmin || false}
              />
            </div>
          ),
          isActive: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
              title={user.is_active ? 'Inativar Usuário' : 'Ativar Usuário'}
            >
              <Switch onChange={() => updateStatus(user)} checked={user.is_active || false} />
            </div>
          ),
          pix: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              {user?.status_plan ? (
                <Label>Usuário com plano ativo</Label>
              ) : (
                <UserButton
                  onClick={() =>
                    history.push(`/user/active/${user?.user_id}`, {
                      user: user,
                    })
                  }
                >
                  Atribuir
                </UserButton>
              )}
            </div>
          ),
          expiresIn: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <Label>
                {user.payment
                  ? moment(user?.payment[0]?.finish_at).format('DD/MM/YYYY')
                  : 'Invalid date'}
              </Label>
            </div>
          ),
          actions: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <Button
                onClick={() => {
                  deleteUser(user)
                }}
                className="small danger"
                title="Excluir Usuário"
              >
                <BiTrash />
              </Button>
            </div>
          ),
          status: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              {user.is_active && user.verified && user.term_accepted && 'Ativo'}
              {user.is_active &&
                !user.verified &&
                !user.term_accepted &&
                'Primeiro login'}
              {user.deleted_at && 'Deletado'}
              {!user.is_active && 'Inativo'}
            </div>
          ),
        }))
      : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    getUsers({ limit: itensPerPage, offset: (page - 1) * itensPerPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const handleGetUserSubmit = (e: FormEvent): void => {
    e.preventDefault()

    if (page === 1) {
      getUsers({ limit: itensPerPage, offset: (page - 1) * itensPerPage })
    } else {
      setPage(1)
    }
  }

  const handleGenerateAllUsersFile = async (): Promise<void> => {
    const allUsers = await getUsersService({})
    setAllUsers(
      allUsers.users.map((user) => ({
        ...user,
        planStr:
          !user.plan || !user.plan.length
            ? 'FREE'
            : !user.plan[0]
            ? 'DESCONHECIDO'
            : user.plan[0].title,
      }))
    )
  }


  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Usuários</span>,
        ]}
      />
      <PageTitle>Usuários</PageTitle>
      <Actions>
        <DefaultButton>
          <CSVLink
            filename={`sgda-usuarios-pagina-${page}.csv`}
            data={users}
            headers={headers}
            className="csv-download-button"
          >
            <FaFileCsv /> Exportar Seleção Atual
          </CSVLink>
        </DefaultButton>

        {allUsers.length ? (
          <DefaultButton>
            <CSVLink
              filename={`sgda-todos-usuarios.csv`}
              data={allUsers}
              headers={headers}
              className="csv-download-button"
            >
              <FaFileCsv /> Exportar Todos Usuários
            </CSVLink>
          </DefaultButton>
        ) : (
          <DefaultButton onClick={handleGenerateAllUsersFile} className="generate-button">
            <FaDownload /> Gerar - Todos Usuários
          </DefaultButton>
        )}

        <form onSubmit={handleGetUserSubmit}>
          <DefaultInput
            type="search"
            placeholder="Nome ou e-mail"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <DefaultButton type="submit">
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </Actions>
      <CardContainer>
        <Card>
          <p>Total de Usuários</p>
          <h2>{total}</h2>
        </Card>
      </CardContainer>
      <Table
        usePagination={false}
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>E-mail</span>,
            propName: 'email',
          },
          {
            headerLabel: <span>Plano</span>,
            propName: 'plan',
          },
          {
            headerLabel: <span>Admin</span>,
            propName: 'isAdmin',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'isActive',
          },
          {
            headerLabel: <span>Definir plano</span>,
            propName: 'pix',
          },
          {
            headerLabel: <span>Data de Expiração</span>,
            propName: 'expiresIn',
          },
          {
            headerLabel: <span>Status</span>,
            propName: 'status',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={usersToBeShown}
        emptyListMessage={'Não foram encontrados usuários!'}
      />

      <div>
        <Pagination
          totalPages={Math.ceil(total / itensPerPage)}
          setPage={(page) => setPage(page)}
          selected={page}
        />
      </div>
    </Container>
  )
}

export default Users
