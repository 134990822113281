import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { BiEdit, BiTrash } from 'react-icons/bi'

import Banner from 'models/banner'

import { getAllBanners, deleteBanner, activateBanner } from 'services/banners'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Box from 'components/Box'

import { Container } from './style'

const Banners: React.FC = () => {
  const history = useHistory()

  const [banners, setBanners] = useState([] as Banner[])

  const getBanners = async (): Promise<void> => {
    const localBanners = await getAllBanners()
    if (localBanners && localBanners.length) {
      setBanners(localBanners)
    }
  }

  const editBanner = useCallback(
    (bannerId: string) => {
      history.push(`edit-banner/${bannerId}`)
    },
    [history]
  )

  const removeBanner = useCallback(async (bannerId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este conteúdo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteBanner(bannerId)

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Conteúdo excluido com sucesso!',
          })

          await getBanners()
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir conteúdo. ${
              error.response && error.response.status === 423
                ? 'Este conteúdo já está associado a um curso!'
                : error.message
            }`,
          })
        }
      }
    })
  }, [])

  const createBanner = (): void => {
    history.push('create-banner')
  }

  const toggleActiveBanner = useCallback(async (bannerId: string) => {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja ativar este conteúdo?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateBanner(bannerId)

          getBanners()
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: `Erro ao ativar conteúdo. ${
              error.response && error.response.status === 423
                ? 'Este conteúdo já está associado a um curso!'
                : error.message
            }`,
          })
        }
      }
    })
  }, [])

  const contentsToBeShown = useMemo(() => {
    return banners && banners.length
      ? banners.map((banner) => ({
          content_id: banner.banner_id,
          title: banner.title,
          description: banner.description,
          active: banner.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <>
              <Button
                onClick={() => editBanner(banner.banner_id)}
                className="small info"
                title="Editar Conteúdo"
              >
                <BiEdit />
              </Button>{' '}
              {!banner.is_active && (
                <Button
                  onClick={() => toggleActiveBanner(banner?.banner_id)}
                  className="small warning"
                  title="Ativar Conteúdo"
                >
                  <AiOutlineCheck />
                </Button>
              )}{' '}
              <Button
                onClick={() => {
                  removeBanner(banner.banner_id)
                }}
                className="small danger"
                title="Excluir Conteúdo"
              >
                <BiTrash />
              </Button>
            </>
          ),
        }))
      : []
  }, [banners, editBanner, removeBanner, toggleActiveBanner])

  useEffect(() => {
    getBanners()
  }, [])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Banners</span>,
        ]}
      />

      <PageTitle>Banners</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createBanner}>Criar Banner</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados banners cadastrados!'}
      />
    </Container>
  )
}

export default Banners
