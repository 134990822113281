import { AiOutlineUser } from 'react-icons/ai'
import { IoDocumentsSharp, IoLibraryOutline } from 'react-icons/io5'
import { FaHome } from 'react-icons/fa'
import { BiVideo, BiPhotoAlbum } from 'react-icons/bi'
import { BsCardList, BsImage } from 'react-icons/bs'
import { ImPencil } from 'react-icons/im'
import { RiGitRepositoryPrivateFill, RiPagesLine } from 'react-icons/ri'

const menus = [
  {
    label: (
      <>
        <span className="icon">
          <FaHome />
        </span>
        <span className="text">Dashboard</span>
      </>
    ),
    path: '/home',
  },
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineUser />
        </span>
        <span className="text">Usuários</span>
      </>
    ),
    path: '/users',
  },
  {
    label: (
      <>
        <span className="icon">
          <IoLibraryOutline />
        </span>
        <span className="text">Categorias</span>
      </>
    ),
    path: '/categories',
  },
  {
    label: (
      <>
        <span className="icon">
          <BsCardList />
        </span>
        <span className="text">Planos</span>
      </>
    ),
    path: '/plans',
  },
  {
    label: (
      <>
        <span className="icon">
          <BiVideo />
        </span>
        <span className="text">Vídeos</span>
      </>
    ),
    path: '/videos',
  },
  {
    label: (
      <>
        <span className="icon">
          <BiPhotoAlbum />
        </span>
        <span className="text">Álbuns</span>
      </>
    ),
    path: '/albums',
  },
  {
    label: (
      <>
        <span className="icon">
          <IoDocumentsSharp />
        </span>
        <span className="text">CMS</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <ImPencil />
            </span>
            <span className="text">Termos de Uso</span>
          </>
        ),
        path: '/terms',
      },
      {
        label: (
          <>
            <span className="icon">
              <RiGitRepositoryPrivateFill />
            </span>
            <span className="text">Políticas de Privacidade</span>
          </>
        ),
        path: '/politics',
      },
      {
        label: (
          <>
            <span className="icon">
              <BsImage />
            </span>
            <span className="text">Banner Landing</span>
          </>
        ),
        path: '/banners',
      },
      {
        label: (
          <>
            <span className="icon">
              <RiPagesLine />
            </span>
            <span className="text">Vantagens Landing</span>
          </>
        ),
        path: '/perks',
      },
    ],
  },
]

export default menus
