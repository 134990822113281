import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { BiEdit, BiTrash } from 'react-icons/bi'

import Content from 'models/content'

import { getAllContents, deleteContent, activateOrInactivateContent } from 'services/contents'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Box from 'components/Box'

import { Container } from './style'

const Videos: React.FC = () => {
  const history = useHistory()

  const [videos, setVideos] = useState([] as Content[])

  const getVideos = async (): Promise<void> => {
    const localVideos = await getAllContents()
    if (localVideos && localVideos.length) {
      setVideos(localVideos)
    }
  }

  const editVideo = useCallback(
    (contentId: string) => {
      history.push(`edit-video/${contentId}`)
    },
    [history]
  )

  const removeVideo = useCallback(async (contentId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este vídeo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteContent(contentId)

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Vídeo excluido com sucesso!',
          })

          await getVideos()
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir vídeo. ${
              error.response && error.response.status === 423
                ? 'Este vídeo já está associado a um curso!'
                : error.message
            }`,
          })
        }
      }
    })
  }, [])

  const createVideo = (): void => {
    history.push('create-video')
  }

  const toggleActiveContent = useCallback(async (contentId: string, activate: boolean) => {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} este vídeo?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateOrInactivateContent(contentId, activate)

          getVideos()
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: `Erro ao ${activate ? 'ativar' : 'inativar'} categoria. ${
              error.response && error.response.status === 423
                ? 'Esta categoria já está associado a um curso!'
                : error.message
            }`,
          })
        }
      }
    })
  }, [])

  const contentsToBeShown = useMemo(() => {
    return videos && videos.length
      ? videos.map((video) => ({
          content_id: video.content_id,
          title: video.title,
          description: video.description,
          active: video.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <>
              <Button
                onClick={() => editVideo(video.content_id)}
                className="small info"
                title="Editar Vídeo"
              >
                <BiEdit />
              </Button>{' '}
              <Button
                onClick={() => toggleActiveContent(video?.content_id, !video.is_active)}
                className="small warning"
                title={(video.is_active ? 'Inativar' : 'Ativar') + ' Conteúdo'}
              >
                {video.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </Button>{' '}
              <Button
                onClick={() => {
                  removeVideo(video.content_id)
                }}
                className="small danger"
                title="Excluir Vídeo"
              >
                <BiTrash />
              </Button>
            </>
          ),
        }))
      : []
  }, [videos, editVideo, removeVideo, toggleActiveContent])

  useEffect(() => {
    getVideos()
  }, [])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Videos</span>,
        ]}
      />

      <PageTitle>Videos</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createVideo}>Criar Video</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados videos cadastrados!'}
      />
    </Container>
  )
}

export default Videos
