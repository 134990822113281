import httpClient from 'http-client'
import User from 'models/user'

interface Filters {
  limit?: number
  offset?: number
  search?: string
}

interface UserResponse {
  total: number
  users: User[]
}

export const getUsers = async (filters: Filters): Promise<UserResponse> => {
  const users = (await httpClient.get('/users', { params: filters })).data

  return users
}

export const getUsersConditions = async (): Promise<User[]> => {
  const users = (await httpClient.get('/users/accept-conditions')).data

  return users
}

export const getCurrentUser = async (): Promise<User> => {
  return (await httpClient.get('users/me')).data
}

export const toAdmin = async (user_id: string): Promise<User> => {
  return (await httpClient.patch('users/' + user_id + '/admin')).data
}

export const updateStatusUser = async (user_id: string, is_active: boolean): Promise<User> => {
  return (await httpClient.patch('users/' + user_id + '/active', { is_active })).data
}

export const deleteUserById = async (user_id: string): Promise<User> => {
  return (await httpClient.delete(`users/${user_id}`)).data
}

export const updateUserPlan = async (data: any): Promise<any> => {
  return (await httpClient.post('payment/checkout-allow-admin', data)).data
}
