import React, { ReactElement, useRef } from 'react'
import { GrFormClose } from 'react-icons/gr'

import Button from 'components/Button'

import { Container, PhotoList, PhotoPreview, RemovePhoto } from './style'

export interface FileWithSrc {
  file: File
  imgSrc: string
}

interface InputPhotosProps {
  name: string
  photos: FileWithSrc[]
  setPhotos: (photos: FileWithSrc[]) => void
}

const InputPhotos = ({ name, photos, setPhotos }: InputPhotosProps): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const files = event.currentTarget.files
    const filesWithSrc = files?.length
      ? Array.from(files).map((file) => {
          const imgSrc = URL.createObjectURL(file)

          return {
            file,
            imgSrc,
          }
        })
      : []

    setPhotos(photos.concat(filesWithSrc))
  }

  const handleButtonClick = (): void => {
    inputRef.current?.click()
  }

  const handleRemovePhoto = (imgSrc: string): void => {
    const filteredPhotos = photos?.length ? photos.filter((photo) => photo.imgSrc !== imgSrc) : []

    setPhotos(filteredPhotos)
  }

  return (
    <Container>
      <Button type="button" onClick={handleButtonClick}>
        Selecionar Fotos
      </Button>

      {!!photos.length && (
        <PhotoList>
          {photos.map((photo) => (
            <PhotoPreview key={photo.imgSrc}>
              <RemovePhoto onClick={() => handleRemovePhoto(photo.imgSrc)}>
                <GrFormClose />
              </RemovePhoto>

              <img src={photo.imgSrc} alt={photo.file.name} />
            </PhotoPreview>
          ))}
        </PhotoList>
      )}

      <input type="file" name={name} ref={inputRef} onChange={handleFileChange} multiple />
    </Container>
  )
}

export default InputPhotos
