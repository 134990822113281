import httpClient from 'http-client'
import Album from 'models/album'
import AlbumForCreate from 'models/for-create/album'
import AlbumForUpdate from 'models/for-update/album'

export const getAlbum = async (albumId: string): Promise<Album> => {
  const album = (await httpClient.get(`albuns/${albumId}`)).data

  return album
}

export const getAllAlbums = async (): Promise<Album[]> => {
  const albums = (await httpClient.get('albuns')).data

  return albums
}

export const createAlbum = async (newAlbum: AlbumForCreate): Promise<Album> => {
  const createCategoryResponse = (await httpClient.post(`albuns`, newAlbum)).data

  return createCategoryResponse
}

export const updateAlbum = async (albumId: string, newContent: AlbumForUpdate): Promise<void> => {
  await httpClient.put(`albuns/${albumId}`, newContent)
}

export const deleteAlbum = async (albumId: string): Promise<void> => {
  await httpClient.delete(`albuns/${albumId}`)
}

export const updateStatusAlbum = async (albumId: string, is_active: boolean): Promise<void> => {
  await httpClient.patch(`albuns/${albumId}/active`, { is_active })
}
