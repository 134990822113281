import styled from 'styled-components'

export const Container = styled.div`
  button {
    padding: 0;

    a.csv-download-button {
      display: block;
      color: white;
      text-decoration: none;
      padding: 15px 20px;
    }
  }
`

export const Label = styled.p`
  font-size: var(--font-small);
  font-weight: 400;
  margin-bottom: 10px;
`
export const UserName = styled.p`
  font-size: var(--font-medium);
  font-weight: 600;
  color: var(--white);
`
export const Buttons = styled.div`
  margin-top: auto;
  margin-left: auto;
  > div {
    > button {
      padding: 15px 20px;
    }
  }
`
export const Date = styled.div`
  input {
    height: 45px;
    font-size: var(--font-small);
    padding: 0 15px;
  }
`

export const CardContainer = styled.div`
  display: flex;
  gap: 25px;
  margin: 30px 0;
`
export const Actions = styled.div`
  display: flex;
  padding: 20px 0;
  align-items: center;
  gap: 8px;

  .generate-button {
    padding: 15px 20px;
    color: var(--white);
  }

  form {
    display: flex;
    margin-left: auto;

    input {
      border-radius: 4px 0 0 4px;
      width: 300px;
    }

    button {
      width: 50px;
      color: var(--white);
      border-radius: 0 4px 4px 0;
      border: 1px solid var(--primary-color);
    }
  }
`

export const UserButton = styled.button`
  background: green;
  color: var(--white);
  padding: 15px !important;
  font-size: var(--font-small);
  border-radius: 30px;
  font-weight: bold;
  border: none;
  cursor: pointer;
`


export const Card = styled.div`
  border-radius: 6px;
  border: 1px solid var(--primary-color);
  padding: 16px;
  width: 100%;
  max-width: 390px;

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: var(--primary-color);
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 5px;
    color: var(--white);
  }
`
