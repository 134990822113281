import httpClient from 'http-client'
import Category from 'models/category'
import CategoryForCreate from 'models/for-create/category'
import CategoryForUpdate from 'models/for-update/category'

export const getCategory = async (categoryId: string): Promise<Category> => {
  const category = (await httpClient.get(`categories/${categoryId}`)).data

  return category
}

export const getAllCategories = async (filters?: any): Promise<Category[]> => {
  const categories = (await httpClient.get('categories', { params: filters })).data

  return categories
}

export const createCategory = async (newCategory: CategoryForCreate): Promise<Category> => {
  const createCategoryResponse = (await httpClient.post(`categories`, newCategory)).data

  return createCategoryResponse
}

export const updateCategory = async (
  categoryId: string,
  newContent: CategoryForUpdate
): Promise<void> => {
  await httpClient.put(`categories/${categoryId}`, newContent)
}

export const deleteCategory = async (categoryId: string): Promise<void> => {
  await httpClient.delete(`categories/${categoryId}`)
}

export const activateOrInactivateCategory = async (
  categoryId: string,
  activate: boolean
): Promise<void> => {
  await httpClient.patch(`categories/${categoryId}/status`, { is_active: activate })
}
