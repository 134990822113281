import httpClient from 'http-client'
import Perk from 'models/perk'
import PerkForCreate from 'models/for-create/perk'
import PerkForUpdate from 'models/for-update/perk'

export const getPerk = async (perkId: string): Promise<Perk> => {
  const perk = (await httpClient.get(`perks/${perkId}`)).data

  return perk
}

export const getAllPerks = async (): Promise<Perk[]> => {
  const perks = (await httpClient.get('perks')).data

  return perks
}

export const createPerk = async (newPerk: PerkForCreate): Promise<Perk> => {
  const createContentResponse = (await httpClient.post(`perks/`, newPerk)).data

  return createContentResponse
}

export const updatePerk = async (perkId: string, newContent: PerkForUpdate): Promise<void> => {
  await httpClient.put(`perks/${perkId}`, newContent)
}

export const deletePerk = async (perkId: string): Promise<void> => {
  await httpClient.delete(`perks/${perkId}`)
}

export const activateOrInactivatePerk = async (
  perkId: string,
  activate: boolean
): Promise<void> => {
  await httpClient.patch(`perks/${perkId}/status`, { is_active: activate })
}
