import httpClient from 'http-client'

interface TermsRequest {
  text: string
  type?: string
}

export const saveTerms = async (data: TermsRequest): Promise<TermsRequest> => {
  const term = (await httpClient.put('/terms', data)).data
  return term
}

export const getTerms = async (params = {}): Promise<TermsRequest> => {
  const term = (await httpClient.get('/terms', { params })).data
  return term
}
