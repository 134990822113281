import { ReactElement } from 'react'

import Routes from 'components/Routes'
import SideBar from 'components/SideBar'

import { Container, Content, LeftContent, RightContent } from './style'

const App = (): ReactElement => (
  <Container>
    <LeftContent>
      <SideBar />
    </LeftContent>

    <RightContent>
      <Content>
        <Routes />
      </Content>
    </RightContent>
  </Container>
)

export default App
