import styled from 'styled-components'

const BreadCrumbContainer = styled.div`
  margin-bottom: 25px;

  & > :not(:first-child) {
    &::before {
      content: '/';
      margin: 0 3px;
    }
  }
  a,
  span {
    font-size: var(--font-small);
    color: var(--primary-color);
  }
`

export { BreadCrumbContainer }
