import ReactPaginate from 'react-paginate'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { Container } from './style'

interface PaginationProps {
  totalPages: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  selected: number
}

interface PaginationData {
  selected: number
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, setPage, selected = 1 }) => {
  const handlePageClick = (data: PaginationData) => {
    setPage(data.selected + 1)
  }

  return (
    <Container>
      <ReactPaginate
        previousLabel={<AiOutlineLeft />}
        nextLabel={<AiOutlineRight />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
        forcePage={selected - 1}
      />
    </Container>
  )
}

export default Pagination
