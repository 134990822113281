import styled from 'styled-components'

const Input = styled.input`
  outline: none;
  font-size: var(--font-small);
  width: 100%;
  height: 44px;
  background: transparent;
  border-radius: 5px;
  border: 1px solid var(--white);
  transition: border-bottom 0.3s;
  color: var(--white);
  padding: 8px 15px;

  &:focus {
    border-color: var(--primary-color);
  }

  &:disabled {
    border-color: var(--gray);
  }

  &[type='checkbox'] {
    width: 15px;
    height: 15px;

    &:hover {
      cursor: pointer;
    }
  }
`

export default Input
