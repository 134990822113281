import styled from 'styled-components'

export const Container = styled.div``

export const ThumbnailUploadContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  img {
    margin-top: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ContentThumbnail = styled.img`
  max-width: 600px;
  background-color: #ebebeb;
`

export const FormButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`

export const ContentUploadOrSelectContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  iframe {
    margin-top: 10px;
    width: 600px;
    height: 340px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ButtonGroupInline = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-left: 10px;
  }
`
