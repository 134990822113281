import axios from 'axios'
import MediaSambaVideos from 'models/media'

const sambaVideosApiBaseUrl = 'https://sambavideos.sambatech.com/v1'

const getMedias = async (filters?: any): Promise<MediaSambaVideos[]> => {
  const medias = (
    await axios.get(
      `${sambaVideosApiBaseUrl}/medias?access_token=${process.env.REACT_APP_SAMBA_ACCESS_TOKEN}&pid=${process.env.REACT_APP_SAMBA_PID}`,
      {
        params: filters,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
  ).data

  return medias
}

const getMedia = async (mediaId: string): Promise<MediaSambaVideos> => {
  const media = (
    await axios.get(
      `${sambaVideosApiBaseUrl}/medias/${mediaId}?access_token=${process.env.REACT_APP_SAMBA_ACCESS_TOKEN}&pid=${process.env.REACT_APP_SAMBA_PID}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
  ).data

  return media
}

export { getMedias, getMedia }
