import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { FiPlus, FiX } from 'react-icons/fi'

import checkEmptyString from 'helpers/check-empty-string'
import {
  createPlan as createPlanService,
  getPlan as getPlanService,
  updatePlan as updatePlanService,
} from 'services/plans'

import Form from 'components/Form'
import FormGroup from 'components/FormGroup'
import Input from 'components/Input'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import TextArea from 'components/TextArea'
import Switch from 'react-switch'

import {
  Container,
  FormButtons,
  PerksContainer,
  ThumbnailUploadContainer,
  ContentThumbnail,
  Title,
} from './style'
import CutImage from 'components/CutImage'
import { hideModal, showModal } from 'helpers/modal'
import { uploadFile } from 'services/files'

interface CreateAndEditPlanProps {
  planId: string
}

const CreateAndEditPlan: React.FC = () => {
  const { planId } = useParams<CreateAndEditPlanProps>()

  const history = useHistory()

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [perks, setPerks] = useState([] as string[])
  const [perk, setPerk] = useState('')
  const [price, setPrice] = useState('')
  const [hasPix, setHasPix] = useState(false)
  const [bannerToUpload, setBannerToUpload] = useState<File>()
  const [pixUrl, setPixUrl] = useState('')
  const [banner, setBanner] = useState('')
  const [mensal, setMensal] = useState('')
  const [semestral, setSemestral] = useState('')
  const [anual, setAnual] = useState('')

  const getImageReference = async (imageFile: any): Promise<string> => {
    try {
      return (await uploadFile(imageFile)).reference
    } catch {
      throw new Error(
        'Erro ao fazer upload da imagem de miniatura. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
      )
    }
  }

  const createPlan = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    if (hasPix) {
      try {
        const priceFloat = parseFloat(price.replace(',', '.'))

        if (checkEmptyString(title)) {
          throw new Error('Informe um título válido para o plano.')
        }

        if (priceFloat <= 0 || isNaN(priceFloat)) {
          throw new Error('Informe um preço válido para o plano.')
        }

        if (hasPix && !bannerToUpload) {
          throw new Error('Selecione um código QR.')
        }

        const pixReference = await getImageReference(bannerToUpload)

        await createPlanService({
          title,
          description: JSON.stringify(perks),
          price: priceFloat,
          info: {
            pix_url: pixUrl,
            pix_image: pixReference,
          },
          discounts: {
            anual: {
              percent: anual ? anual : 0,
            },
            mensal: {
              percent: mensal ? mensal : 0,
            },
            semestral: {
              percent: semestral ? semestral : 0,
            },
          },
        })

        Swal.fire({
          title: 'Sucesso!',
          text: 'Plano criado com sucesso!',
          icon: 'success',
        })

        goToPlans()
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao criar o plano. ' + error.message,
          icon: 'error',
        })
      }
    } else {
      try {
        const priceFloat = parseFloat(price.replace(',', '.'))

        if (checkEmptyString(title)) {
          throw new Error('Informe um título válido para o plano.')
        }

        if (priceFloat <= 0 || isNaN(priceFloat)) {
          throw new Error('Informe um preço válido para o plano.')
        }

        await createPlanService({
          title,
          description: JSON.stringify(perks),
          price: priceFloat,
          info: {},
          discounts: {
            anual: {
              percent: anual ? anual : 0,
            },
            mensal: {
              percent: mensal ? mensal : 0,
            },
            semestral: {
              percent: semestral ? semestral : 0,
            },
          },
        })

        Swal.fire({
          title: 'Sucesso!',
          text: 'Plano criado com sucesso!',
          icon: 'success',
        })

        goToPlans()
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao criar o plano. ' + error.message,
          icon: 'error',
        })
      }
    }
  }

  const updatePlan = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()
    if (hasPix) {
      try {
        const priceFloat = parseFloat(price.replace(',', '.'))

        if (checkEmptyString(title)) {
          throw new Error('Informe um título valido para a categoria.')
        }

        if (priceFloat <= 0 || isNaN(priceFloat)) {
          throw new Error('Informe um preço válido para o plano.')
        }

        if (hasPix && !banner) {
          throw new Error('Selecione um código QR.')
        }

        const resolvePixReference = async (): Promise<string> => {
          if (bannerToUpload) return await getImageReference(bannerToUpload)
          return banner
        }

        await updatePlanService(planId, {
          title,
          description: JSON.stringify(perks),
          price: priceFloat,
          info: {
            pix_url: pixUrl,
            pix_image: await resolvePixReference(),
          },
          discounts: {
            anual: {
              percent: anual ? anual : 0,
            },
            mensal: {
              percent: mensal ? mensal : 0,
            },
            semestral: {
              percent: semestral ? semestral : 0,
            },
          },
        })

        Swal.fire({
          title: 'Sucesso!',
          text: 'Plano editado com sucesso!',
          icon: 'success',
        })

        goToPlans()
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao editar o plano. ' + error.message,
          icon: 'error',
        })
      }
    } else {
      try {
        const priceFloat = parseFloat(price.replace(',', '.'))

        if (checkEmptyString(title)) {
          throw new Error('Informe um título valido para a categoria.')
        }

        if (priceFloat <= 0 || isNaN(priceFloat)) {
          throw new Error('Informe um preço válido para o plano.')
        }

        const data = {
          title,
          description: JSON.stringify(perks),
          price: priceFloat,
          info: {
            pix_url: '',
            pix_image: '',
          },
          discounts: {
            anual: {
              percent: anual ? anual : 0,
            },
            mensal: {
              percent: mensal ? mensal : 0,
            },
            semestral: {
              percent: semestral ? semestral : 0,
            },
          },
        }

        await updatePlanService(planId, data)

        Swal.fire({
          title: 'Sucesso!',
          text: 'Plano editado com sucesso!',
          icon: 'success',
        })

        goToPlans()
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao editar o plano. ' + error.message,
          icon: 'error',
        })
      }
    }
  }

  const goToPlans = (): void => {
    history.push('/plans')
  }

  const getPlan = useCallback(async () => {
    if (planId) {
      const plan = await getPlanService(planId)
      if (plan && Object.keys(plan).length) {
        setTitle(plan.title)
        setPrice(plan.price.toString())
        setPerks(JSON.parse(plan.description))
        setAnual(plan?.discounts?.anual?.percent)
        setSemestral(plan?.discounts?.semestral?.percent)
        setMensal(plan?.discounts?.mensal?.percent)
        if (plan?.info?.pix_image) {
          setHasPix(true)
          setPixUrl(plan?.info?.pix_url)
          setBanner(plan?.info?.pix_image)
        }
      }
    }
  }, [planId])

  useEffect(() => {
    getPlan()
  }, [getPlan])

  const isEditting = useMemo(() => {
    if (planId) {
      return true
    }

    return false
  }, [planId])

  const addPerk = () => {
    const regex = /^\s+$/
    if (perks && perk != '' && !regex.test(perk)) {
      setPerks([...perks, perk])
      setPerk('')
    }
  }

  const removePeak = (indexRemove: number) => {
    setPerks(perks.filter((item, index) => indexRemove !== index))
  }

  const onCutBanner = (file: File): void => {
    if (file) {
      setBannerToUpload(file)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setBanner(`${reader.result}`)
      hideModal()
    }
  }

  const selectBanner = (): void => {
    showModal('Selecionar Imagem do QR Code', <CutImage aspect={1.812} onCutImage={onCutBanner} />)
  }

  const handlePix = () => {
    setHasPix(!hasPix)
    if (!hasPix) {
      setPixUrl('')
      setBanner('')
      setBannerToUpload(undefined)
    }
  }

  useEffect(() => {
    if (!hasPix) {
      setPixUrl('')
      setBanner('')
      setBannerToUpload(undefined)
    }
  }, [hasPix])
  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <Link key={2} to="/plans">
            Planos
          </Link>,
          <span key={3}>{isEditting ? 'Editar' : 'Criar'} Planos</span>,
        ]}
      />

      <PageTitle>{isEditting ? 'Editar' : 'Criar'} Planos</PageTitle>

      <Form>
        <FormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <Input id="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </FormGroup>

        <PerksContainer>
          <FormGroup>
            <label className="required" htmlFor="title">
              Vantagens
            </label>
            <div>
              <Input
                id="title"
                value={perk}
                onChange={(e) => setPerk(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault()
                    e.stopPropagation()
                    addPerk()
                  }
                }}
              />{' '}
              <button type="button" onClick={addPerk}>
                <FiPlus />
              </button>
            </div>
          </FormGroup>

          <div>
            {perks.map((perk, index) => (
              <div key={index}>
                <span>{perk}</span>
                <button onClick={() => removePeak(index)} type="button">
                  <FiX />
                </button>
              </div>
            ))}
          </div>
        </PerksContainer>

        <FormGroup>
          <label htmlFor="price">Preço</label>
          <Input id="price" value={price} onChange={(e) => setPrice(e.target.value)} required />
        </FormGroup>

        <FormGroup>
          <Title>Boleto</Title>
          <>
            <FormGroup>
              <label htmlFor="mensal">Desconto Mensal Boleto</label>
              <Input
                id="mensal"
                value={mensal}
                onChange={(e) => setMensal(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="semestral">Desconto Semestral Boleto</label>
              <Input
                id="semestral"
                value={semestral}
                type="number"
                onChange={(e) => setSemestral(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="anual">Desconto Anual Boleto</label>
              <Input
                id="anual"
                value={anual}
                type="number"
                onChange={(e) => setAnual(e.target.value)}
                required
              />
            </FormGroup>
          </>
        </FormGroup>

        <FormGroup>
          <Title>
            PIX
            <Switch id="pix" onChange={handlePix} checked={hasPix} />
          </Title>
          {hasPix && (
            <>
              <FormGroup>
                <label htmlFor="pixCode">Código do Pix</label>
                <Input
                  id="pixCode"
                  value={pixUrl}
                  onChange={(e) => setPixUrl(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="pixQR">QR Code</label>
                <ThumbnailUploadContainer>
                  <Button type="button" onClick={selectBanner}>
                    Selecionar Imagem QR Code
                  </Button>
                  {banner && <ContentThumbnail src={banner} />}
                </ThumbnailUploadContainer>
              </FormGroup>
            </>
          )}
        </FormGroup>

        <FormButtons>
          <Button type="button" className="danger" onClick={goToPlans}>
            Cancelar
          </Button>
          <Button onClick={(e) => (isEditting ? updatePlan(e) : createPlan(e))} className="success">
            Salvar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  )
}

export default CreateAndEditPlan
