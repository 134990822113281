import React, { ReactElement, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import BreadCrumb from 'components/BreadCrumb'
import PageTitle from 'components/PageTitle'
import Button from 'components/Button'

import { Container, ButtonContainer } from './style'
import { getTerms, saveTerms } from 'services/terms'
import Swal from 'sweetalert2'

const Politics = (): ReactElement => {
  const [editorState, setEditorState] = useState<EditorState | undefined>(undefined)

  const handleSubmit = async (): Promise<void> => {
    if (editorState) {
      try {
        const text = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        await saveTerms({ text, type: 'policy' })

        Swal.fire({
          title: 'Sucesso!',
          text: 'Política de Privacidade atualizada com sucesso!',
          icon: 'success',
        })
      } catch (err) {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao atualizar a política de privacidade!',
          icon: 'error',
        })
      }
    }
  }

  useEffect(() => {
    const getTermsData = async (): Promise<void> => {
      const termData = await getTerms({ type: 'policy' })

      if (!termData.text) {
        setEditorState(EditorState.createEmpty())
        return
      }

      const blocksFromHtml = htmlToDraft(termData.text)

      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      const editorState = EditorState.createWithContent(contentState)
      setEditorState(editorState)
    }

    getTermsData()
  }, [])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Políticas de Privacidade</span>,
        ]}
      />

      <PageTitle>Políticas de Privacidade</PageTitle>

      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        editorClassName="editor"
        toolbarClassName="toolbar"
      />

      <ButtonContainer>
        <Button className="success" type="button" onClick={handleSubmit}>
          Salvar
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default Politics
