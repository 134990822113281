import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import SambaUploader from 'samba-uploader'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'

import MediaSambaVideos from 'models/media'
import { getMedias } from 'services/medias'

import Button from 'components/Button'
import SambaVideosContentCard from 'components/SambaVideosContentCard'
import UploadProgressBar from './components/UploadProgressBar'

import {
  ContentList,
  ContentListContainer,
  ContentListFilter,
  ContentListFilters,
  ContentUpload,
  SelectSambaVideosContentContainer,
} from './style'

interface SelectSambaVideosContentProps {
  onSelectContent: (content: MediaSambaVideos) => void
}

const SelectSambaVideosContent: React.FC<SelectSambaVideosContentProps> = ({ onSelectContent }) => {
  const uploadButton = useRef(null)

  const numberOfMediasPerPage = 10

  const [loadingMoreMedias, setLoadingMoreMedias] = useState(false)
  const [shouldShowLoadMoreMedias, setShouldShowLoadMoreMedias] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [completeUpload, setCompleteUpload] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [contents, setContents] = useState([] as MediaSambaVideos[])
  const [shouldShowLoading, setShouldShowLoading] = useState(true)
  const [sambaUploader, setSambaUploader] = useState({} as any)
  const [numberOfContentsToBeShown, setNumberOfContentsToBeShown] = useState(1)

  const getSambaVideosContents = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    setCompleteUpload(false)
    setContents([])
    const localContents = await getMedias({
      search: searchText,
      limit: numberOfMediasPerPage,
      types: 'VIDEO',
    })
    if (localContents && localContents.length) {
      setContents(localContents)
      setShouldShowLoading(true)
    } else {
      setShouldShowLoading(false)
    }
  }

  const loadMoreMedias = async () => {
    setLoadingMoreMedias(true)

    const localContents = await getMedias({
      search: searchText,
      start: numberOfContentsToBeShown,
      limit: numberOfMediasPerPage,
      types: 'VIDEO',
    })

    if (localContents && localContents.length) {
      const localJoinedContents = [...contents, ...localContents]

      if (localJoinedContents.length % numberOfMediasPerPage > 0) {
        setShouldShowLoadMoreMedias(false)
      }

      setContents(localJoinedContents)
      setShouldShowLoading(true)
    } else {
      setShouldShowLoading(false)
      setShouldShowLoadMoreMedias(false)
    }

    setNumberOfContentsToBeShown(numberOfContentsToBeShown + 1)
    setLoadingMoreMedias(false)
  }

  useEffect(
    () => {
      getSambaVideosContents()
    },
    // eslint-disable-next-line
    [completeUpload]
  )

  useEffect(() => {
    if (uploadButton.current && (!sambaUploader || !Object.keys(sambaUploader).length)) {
      setSambaUploader(
        new SambaUploader({
          button: uploadButton.current,
          sambaUploadKey: process.env.REACT_APP_SAMBA_UPLOAD_KEY,
          allowedExtensions: [
            'avi',
            'wmv',
            'wma',
            'mov',
            'flv',
            'rm',
            'rmvb',
            'mp4',
            'mp3',
            'mkv',
            '3gp',
          ],
        })
      )
    }
  }, [uploadButton, sambaUploader])

  useEffect(() => {
    if (sambaUploader && Object.keys(sambaUploader).length) {
      sambaUploader.on('start', () => {
        Swal.fire({
          title: 'Iniciando Upload',
          text: 'Upload de conteúdo iniciado!',
          timer: 2000,
        })
      })

      sambaUploader.on('progress', (event: any) => {
        const progress = (event.uploadedBytes / event.totalBytes) * 100
        if (progress) {
          setUploadProgress(progress)
          setIsUploading(true)
        }
      })

      sambaUploader.on('complete', async (event: any) => {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Upload realizado com sucesso! Aguarde o tempo de encoding que pode durar até 10 min, após isso seu vídeo estará disponível para cadastro.',
          timer: 4000,
        })

        setUploadProgress(0)
        setIsUploading(false)
        setCompleteUpload(true)
      })
    }
  }, [sambaUploader])

  return (
    <SelectSambaVideosContentContainer>
      <ContentUpload ref={uploadButton}>
        <AiOutlineCloudUpload size={128} />
        {!isUploading && <p>Fazer Upload de Conteúdo</p>}
        {isUploading && <p>Aguarde o Upload do Conteúdo</p>}
        {uploadProgress > 0 ? <UploadProgressBar progress={uploadProgress} /> : <></>}
      </ContentUpload>

      <ContentListContainer>
        <ContentListFilters onSubmit={getSambaVideosContents}>
          <ContentListFilter
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="search"
          />
          <Button type="submit">
            <BsSearch />
          </Button>
        </ContentListFilters>

        <ContentList>
          {contents && contents.length ? (
            <>
              {contents.map((content) => (
                <SambaVideosContentCard
                  onClick={onSelectContent}
                  key={content.id}
                  content={content}
                />
              ))}
              {shouldShowLoadMoreMedias ? (
                <div className="center">
                  {loadingMoreMedias ? (
                    <div className="spinner small"></div>
                  ) : (
                    <Button onClick={loadMoreMedias}>Carregar Mais</Button>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          ) : shouldShowLoading ? (
            <div className="spinner"></div>
          ) : (
            <>Nenhum conteúdo encontrado.</>
          )}
        </ContentList>
      </ContentListContainer>
    </SelectSambaVideosContentContainer>
  )
}

export default SelectSambaVideosContent
