import Compressor from 'compressorjs'

interface GenerateThumbnailArgs {
  file: File
  maxWidth: number
  callback: (thumbnail: File) => void
}

const generateThumbnail = ({ file, maxWidth, callback }: GenerateThumbnailArgs): void => {
  new Compressor(file, {
    quality: 0.8,
    maxWidth: maxWidth,
    success(result) {
      const blobToFile = new File([result], file.name, { type: result.type })
      callback(blobToFile)
    },
    error(error) {
      throw new Error(error.message)
    },
  })
}

export default generateThumbnail
