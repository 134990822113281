import httpClient from 'http-client'
import UploadedFile from 'models/file'

export const uploadFile = async (file: File): Promise<UploadedFile> => {
  const formData = new FormData()
  formData.append('file', file)

  const uploadFileResponse = (
    await httpClient.post(`files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data

  return uploadFileResponse
}
