import styled from 'styled-components'

export const Container = styled.div`
  .editor {
    background: #222;
    height: 500px;
    padding: 15px;
  }

  .toolbar {
    padding: 15px;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`
