import React, { useEffect, useState } from 'react'

import getCurrentUser from 'helpers/get-current-user'
import User from 'models/user'

import { ProfileContainer } from './style'

const Profile: React.FC = () => {
  const [user, setUser] = useState({} as User)

  useEffect(() => {
    ;(async () => {
      setUser(await getCurrentUser())
    })()
  }, [])

  return (
    <ProfileContainer>
      <h2>Seja Bem Vindo, {user.name || 'Desconhecido'}!</h2>
    </ProfileContainer>
  )
}

export default Profile
