import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

import { hideModal, showModal } from 'helpers/modal'
import checkEmptyString from 'helpers/check-empty-string'

import MediaSambaVideos from 'models/media'

import {
  createBanner as createBannerService,
  getBanner as getBannerService,
  updateBanner as updateBannerService,
} from 'services/banners'
import { uploadFile } from 'services/files'

import Form from 'components/Form'
import FormGroup from 'components/FormGroup'
import Input from 'components/Input'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import TextArea from 'components/TextArea'
import CutImage from 'components/CutImage'
import SelectSambaVideosContent from 'components/SelectSambaVideosContent'

import {
  Container,
  ContentThumbnail,
  ThumbnailUploadContainer,
  FormButtons,
  ContentUploadOrSelectContainer,
  ButtonGroupInline,
} from './style'

interface CreateAndEditBannerProps {
  bannerId: string
}

const CreateAndEditBanner: React.FC = () => {
  const { bannerId } = useParams<CreateAndEditBannerProps>()
  const SAMBA_PLAYER_URL = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${process.env.REACT_APP_PLAYER_KEY}`

  const history = useHistory()

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [logoToUpload, setLogoToUpload] = useState<File>()
  const [logo, setLogo] = useState('')
  const [bannerToUpload, setBannerToUpload] = useState<File>()
  const [banner, setBanner] = useState('')
  const [reference, setReference] = useState('')

  const createBanner = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o conteúdo.')
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válida para o conteúdo.')
      }

      if (!bannerToUpload) {
        throw new Error('Selecione um banner.')
      }

      if (!logoToUpload) {
        throw new Error('Selecione uma logo.')
      }

      if (!reference) {
        throw new Error('Selecione um vídeo.')
      }

      const bannerReference = await getImageReference(bannerToUpload)
      const logoReference = await getImageReference(logoToUpload)

      await createBannerService({
        title,
        description,
        file: bannerReference,
        logo: logoReference,
        video_reference: reference,
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo criado com sucesso!',
        icon: 'success',
      })

      goToBanners()
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o conteúdo. ' + error.message,
        icon: 'error',
      })
    }
  }

  const updateBanner = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o conteúdo.')
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válida para o conteúdo.')
      }

      if (!banner && !bannerToUpload) {
        throw new Error('Selecione um banner.')
      }

      if (!logo && !logoToUpload) {
        throw new Error('Selecione uma logo.')
      }

      if (!reference) {
        throw new Error('Selecione um vídeo.')
      }

      const resolveBanner = async (): Promise<string> => {
        if (bannerToUpload) return await getImageReference(bannerToUpload)
        return banner
      }
      const resolveLogo = async (): Promise<string> => {
        if (logoToUpload) return await getImageReference(logoToUpload)
        return logo
      }

      await updateBannerService(bannerId, {
        title,
        description,
        file: await resolveBanner(),
        logo: await resolveLogo(),
        video_reference: reference,
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo editado com sucesso!',
        icon: 'success',
      })

      goToBanners()
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o conteúdo. ' + error.message,
        icon: 'error',
      })
    }
  }

  const onSelectContent = (sambaVideosContent: MediaSambaVideos): void => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0]

        if (firstFile) {
          setReference(sambaVideosContent.id)
        }
      }
    }

    hideModal()
  }

  const selectContent = (): void => {
    showModal('Selecionar Conteúdo', <SelectSambaVideosContent onSelectContent={onSelectContent} />)
  }

  const selectBanner = (): void => {
    showModal(
      'Selecionar Imagem de Miniatura',
      <CutImage aspect={1.812} onCutImage={onCutBanner} />
    )
  }

  const onCutBanner = (file: File): void => {
    if (file) {
      setBannerToUpload(file)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setBanner(`${reader.result}`)

      hideModal()
    }
  }

  const selectLogo = (): void => {
    showModal('Selecionar Imagem de Miniatura', <CutImage aspect={1.812} onCutImage={onCutLogo} />)
  }

  const onCutLogo = (file: File): void => {
    if (file) {
      setLogoToUpload(file)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setLogo(`${reader.result}`)

      hideModal()
    }
  }

  const getImageReference = async (imageFile: File): Promise<string> => {
    try {
      return (await uploadFile(imageFile)).reference
    } catch {
      throw new Error(
        'Erro ao fazer upload da imagem de miniatura. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
      )
    }
  }

  const goToBanners = (): void => {
    history.push('/banners')
  }

  const getBanner = useCallback(async () => {
    if (bannerId) {
      const banner = await getBannerService(bannerId)
      if (banner && Object.keys(banner).length) {
        setTitle(banner.title)
        setDescription(banner.description)
        setBanner(banner.file)
        setLogo(banner.logo)
        setReference(banner.video_reference)
      }
    }
  }, [bannerId])

  useEffect(() => {
    getBanner()
  }, [getBanner])

  const isEditting = useMemo(() => {
    if (bannerId) {
      return true
    }

    return false
  }, [bannerId])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <Link key={2} to="/banners">
            Banners
          </Link>,
          <span key={3}>{isEditting ? 'Editar' : 'Criar'} Banner</span>,
        ]}
      />

      <PageTitle>{isEditting ? 'Editar' : 'Criar'} Banner</PageTitle>

      <Form>
        <FormGroup>
          <label htmlFor="title" className="required">
            Título
          </label>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} id="title" required />
        </FormGroup>

        <FormGroup>
          <label htmlFor="description" className="required">
            Descrição
          </label>
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
            required
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="reference" className="required">
            Conteúdo
          </label>

          <ContentUploadOrSelectContainer>
            <ButtonGroupInline>
              <Button type="button" onClick={selectContent}>
                Selecionar Conteúdo
              </Button>
            </ButtonGroupInline>
            {reference ? (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={`${SAMBA_PLAYER_URL}/${reference}`}
                frameBorder={0}
              ></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </FormGroup>

        <FormGroup>
          <label htmlFor="banner" className="required">
            Banner
          </label>

          <ThumbnailUploadContainer>
            <Button type="button" onClick={selectBanner}>
              Selecionar Banner
            </Button>

            {banner && <ContentThumbnail src={banner} />}
          </ThumbnailUploadContainer>
        </FormGroup>

        <FormGroup>
          <label htmlFor="banner" className="required">
            Logo
          </label>

          <ThumbnailUploadContainer>
            <Button type="button" onClick={selectLogo}>
              Selecionar Logo
            </Button>

            {logo && <ContentThumbnail src={logo} />}
          </ThumbnailUploadContainer>
        </FormGroup>

        <FormButtons>
          <Button type="button" className="danger" onClick={goToBanners}>
            Cancelar
          </Button>
          <Button
            onClick={(e) => (isEditting ? updateBanner(e) : createBanner(e))}
            className="success"
          >
            Salvar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  )
}

export default CreateAndEditBanner
