import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import WebFont from 'webfontloader'

import 'react-datepicker/dist/react-datepicker.css'
import GlobalStyles from 'styles/global'

import App from 'components/App'
import Login from 'pages/Login'
import Loading from 'components/Loading'

WebFont.load({
  google: {
    families: ['Montserrat: 200,400,700,800'],
  },
})

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />

    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />

        <Route component={App} />
      </Switch>
    </Router>

    <Loading />
  </React.StrictMode>,
  document.getElementById('root')
)
