import httpClient from 'http-client'
import Content from 'models/content'
import ContentForCreate from 'models/for-create/content'
import ContentForUpdate from 'models/for-update/content'

export const getContent = async (contentId: string): Promise<Content> => {
  const content = (await httpClient.get(`contents/${contentId}`)).data

  return content
}

export const getAllContents = async (): Promise<Content[]> => {
  const contents = (await httpClient.get('contents')).data

  return contents
}

export const createContent = async (newContent: ContentForCreate): Promise<Content> => {
  const createContentResponse = (await httpClient.post(`contents/`, newContent)).data

  return createContentResponse
}

export const updateContent = async (
  contentId: string,
  newContent: ContentForUpdate
): Promise<void> => {
  await httpClient.put(`contents/${contentId}`, newContent)
}

export const deleteContent = async (contentId: string): Promise<void> => {
  await httpClient.delete(`contents/${contentId}`)
}

export const activateOrInactivateContent = async (
  contentId: string,
  activate: boolean
): Promise<void> => {
  await httpClient.patch(`contents/${contentId}/status`, { is_active: activate })
}
