import React from 'react'
import ReactDOM from 'react-dom'
import { GrFormClose } from 'react-icons/gr'
import {
  ModalCloseButton,
  ModalContent,
  ModalContentContainer,
  ModalHeader,
  ModalTitle,
} from './style'

const showModal = (title: string, content: React.ReactElement) => {
  const modal = document.createElement('div')
  modal.id = 'global-modal'

  document.body.appendChild(modal)

  ReactDOM.render(
    <ModalContentContainer>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <ModalCloseButton type="button" onClick={hideModal}>
          <GrFormClose />
        </ModalCloseButton>
      </ModalHeader>
      <ModalContent>{content}</ModalContent>
    </ModalContentContainer>,
    document.getElementById('global-modal')
  )
}

const hideModal = () => {
  const modal = document.getElementById('global-modal')
  if (modal) {
    modal.remove()
  }
}

export { showModal, hideModal }
