const isAdmin = (roles: string[] | null): boolean => {
  if (!roles) {
    return false
  }

  const adminRole = 'Admin'
  const rolesUpper = roles.map((item: string) => item.toUpperCase())
  if (rolesUpper.indexOf(adminRole.toUpperCase()) < 0) {
    return false
  }

  return true
}

export default isAdmin
