import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

import generateThumbnail from 'helpers/generate-thumbnail'
import checkEmptyString from 'helpers/check-empty-string'
import { hideModal, showModal } from 'helpers/modal'
import {
  createCategory as createCategoryService,
  getCategory,
  updateCategory as updateCategoryService,
} from 'services/categories'
import { uploadFile } from 'services/files'

import Form from 'components/Form'
import FormGroup from 'components/FormGroup'
import Input from 'components/Input'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import TextArea from 'components/TextArea'
import CutImage from 'components/CutImage'

import { Container, ContentThumbnail, ThumbnailUploadContainer, FormButtons } from './style'

interface CreateAndEditCategoryProps {
  categoryId: string
}

const CreateAndEditCategory: React.FC = () => {
  const { categoryId } = useParams<CreateAndEditCategoryProps>()

  const history = useHistory()

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
  const [thumbnail, setThumbnail] = useState('')
  const [free, setFree] = useState(false)
  const [highlight, setHighlight] = useState(false)
  const [home, setHome] = useState(false)

  const createCategory = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título valido para a categoria.')
      }

      if (!thumbnailFileToUpload) {
        throw new Error('Selecione uma imagem de miniatura.')
      }

      const imageReference = await getImageReference(thumbnailFileToUpload)

      await createCategoryService({
        title: title,
        description: description.trim().length ? description : undefined,
        thumbnail: imageReference,
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Categoria criada com sucesso!',
        icon: 'success',
      })

      goToCategories()
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar a categoria. ' + error.message,
        icon: 'error',
      })
    }
  }

  const updateCategory = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título valido para a categoria.')
      }

      if (!thumbnail && !thumbnailFileToUpload) {
        throw new Error('Selecione uma imagem de miniatura.')
      }

      const resolveCategoryThumbnail = async (): Promise<string> => {
        if (thumbnailFileToUpload) return await getImageReference(thumbnailFileToUpload)
        return thumbnail
      }

      await updateCategoryService(categoryId, {
        title: title,
        description: description || undefined,
        thumbnail: await resolveCategoryThumbnail(),
        info: {
          free,
          highlight,
          home,
        },
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo editado com sucesso!',
        icon: 'success',
      })

      goToCategories()
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o conteúdo. ' + error.message,
        icon: 'error',
      })
    }
  }

  const getImageReference = async (imageFile: File): Promise<string> => {
    try {
      return (await uploadFile(imageFile)).reference
    } catch {
      throw new Error(
        'Erro ao fazer upload da imagem de miniatura. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
      )
    }
  }

  const selectThumbnail = (): void => {
    showModal('Selecionar Imagem de Miniatura', <CutImage aspect={1.812} onCutImage={onCutImage} />)
  }

  const onCutImage = (file: File): void => {
    if (file) {
      generateThumbnail({
        file,
        maxWidth: 375,
        callback: (thumbnail) => setThumbnailFileToUpload(thumbnail),
      })

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setThumbnail(`${reader.result}`)

      hideModal()
    }
  }

  const goToCategories = (): void => {
    history.push('/categories')
  }

  const getContent = useCallback(async () => {
    if (categoryId) {
      const content = await getCategory(categoryId)
      if (content && Object.keys(content).length) {
        setTitle(content.title)
        setDescription(content.description)
        setThumbnail(content.thumbnail)
        setFree(content.info.free)
        setHighlight(content.info.highlight)
        setHome(content.info.home)
      }
    }
  }, [categoryId])

  useEffect(() => {
    getContent()
  }, [getContent])

  const isEditting = useMemo(() => {
    if (categoryId) {
      return true
    }

    return false
  }, [categoryId])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <Link key={2} to="/categories">
            Categorias
          </Link>,
          <span key={3}>{isEditting ? 'Editar' : 'Criar'} Categorias</span>,
        ]}
      />

      <PageTitle>{isEditting ? 'Editar' : 'Criar'} Categorias</PageTitle>

      <Form>
        <FormGroup>
          <label htmlFor="title" className="required">
            Título
          </label>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} id="title" required />
        </FormGroup>

        <FormGroup>
          <label htmlFor="description">Descrição</label>
          <TextArea
            value={description || ''}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
            required
          />
        </FormGroup>

        <FormGroup type="checkbox">
          <label htmlFor="free">Grátis</label>
          <Input
            id="free"
            type="checkbox"
            checked={free}
            onChange={(e) => setFree(e.target.checked)}
          />
        </FormGroup>

        {/* <FormGroup type="checkbox">
          <label htmlFor="highlight">Destaque</label>
          <Input
            id="highlight"
            type="checkbox"
            checked={highlight}
            onChange={(e) => setHighlight(e.target.checked)}
          />
        </FormGroup> */}

        <FormGroup type="checkbox">
          <label htmlFor="home">Home</label>
          <Input
            id="home"
            type="checkbox"
            checked={home}
            onChange={(e) => setHome(e.target.checked)}
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="thumbnail" className="required">
            Miniatura
          </label>

          <ThumbnailUploadContainer>
            <Button type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Miniatura
            </Button>

            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </FormGroup>

        <FormButtons>
          <Button type="button" className="danger" onClick={goToCategories}>
            Cancelar
          </Button>
          <Button
            onClick={(e) => (isEditting ? updateCategory(e) : createCategory(e))}
            className="success"
          >
            Salvar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  )
}

export default CreateAndEditCategory
