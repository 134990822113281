import DefaultButton from 'components/DefaultButton'
import React, { useEffect, useMemo, useState } from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { DefaultTablePagination, StyledTable, Tbody, Td, Th, Thead, Tr } from './style'

interface HeaderConfig {
  propName: string
  headerLabel: React.ReactElement
  noWrap?: boolean
  order?: (items: any, currentOrder: string) => void
  attributes?: React.StyleHTMLAttributes<HTMLElement>
}

interface ITableProps {
  headersConfig: HeaderConfig[]
  items: any[]
  emptyListMessage?: string
  itemsPerPage?: number
  usePagination?: boolean
}

interface ExtendedWindow extends Window {
  $clamp: any
}

declare let window: ExtendedWindow

const Table: React.FC<ITableProps> = ({
  headersConfig,
  items: initialItems,
  emptyListMessage,
  itemsPerPage: initialItemsPerPage,
  usePagination: initialUsePagination,
}) => {
  const [items, setItems] = useState([] as any[])
  const [currentOrder, setCurrentOrder] = useState('')
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [usePagination, setUsePagination] = useState(true)

  const changeCurrentPage = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const removeAccentuation = (str: string) => {
    const com_acento = `ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ`
    const sem_acento = `AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr`
    let novastr = ''
    for (let i = 0; i < str.length; i++) {
      let troca = false
      for (let a = 0; a < com_acento.length; a++) {
        if (str.substr(i, 1) == com_acento.substr(a, 1)) {
          novastr += sem_acento.substr(a, 1)
          troca = true
          break
        }
      }
      if (troca == false) {
        novastr += str.substr(i, 1)
      }
    }
    return novastr
  }

  const configureDefaultOrder = () => {
    for (const headerConfig of headersConfig) {
      if (!headerConfig.order) {
        const firstItem = items && items.length && items[0]
        if (firstItem) {
          const itemProp = firstItem[headerConfig.propName]
          if (
            !itemProp ||
            (itemProp && (typeof itemProp === 'string' || typeof itemProp === 'number'))
          ) {
            headerConfig.order = (localItems: any[], currentOrder: string) => {
              setItems([
                ...items.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes(headerConfig.propName) &&
                    currentOrder.replace(headerConfig.propName, '').includes('asc')

                  const treatedAProp = a[headerConfig.propName]
                    ? removeAccentuation(`${a[headerConfig.propName]}`.trim().toLocaleLowerCase())
                    : ''
                  const treatedBProp = b[headerConfig.propName]
                    ? removeAccentuation(`${b[headerConfig.propName]}`.trim().toLocaleLowerCase())
                    : ''

                  if (isDescendant) {
                    return treatedAProp < treatedBProp ? 1 : -1
                  } else {
                    return treatedAProp < treatedBProp ? -1 : 1
                  }
                }),
              ])
            }
          }
        }
      }
    }
  }

  const itemsToShow = useMemo(() => {
    return usePagination ? [...items].splice(currentPage * itemsPerPage, itemsPerPage) : items
  }, [items, currentOrder, itemsPerPage, currentPage])

  const numberOfPages = useMemo(() => {
    return Math.ceil(items.length / itemsPerPage)
  }, [items, itemsPerPage, currentPage])

  useEffect(() => {
    if (initialItemsPerPage) {
      setItemsPerPage(initialItemsPerPage)
    }
  }, [initialItemsPerPage])

  useEffect(() => {
    setItems(initialItems)
  }, [initialItems])

  useEffect(() => {
    if (initialUsePagination !== undefined) {
      setUsePagination(initialUsePagination)
    }
  }, [initialUsePagination])

  useEffect(() => {
    configureDefaultOrder()
  }, [headersConfig, items])

  useEffect(() => {
    if (window.$clamp) {
      const tableTds = Array.from(document.querySelectorAll('.table-td'))
      for (const td of tableTds) {
        window.$clamp(td, { clamp: 3 })
      }
    }
  }, [currentPage, items])

  return (
    <>
      <StyledTable>
        <Thead>
          <Tr>
            {headersConfig && headersConfig.length ? (
              headersConfig.map((headerConfig, index) => (
                <Th key={index}>{headerConfig.headerLabel}</Th>
              ))
            ) : (
              <></>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {itemsToShow && itemsToShow.length ? (
            itemsToShow.map((item, index) => (
              <Tr key={index}>
                {item && Object.keys(item).length && headersConfig && headersConfig.length ? (
                  headersConfig.map((headerConfig, index) => (
                    <Td
                      key={index}
                      style={{
                        whiteSpace: headerConfig.noWrap ? 'nowrap' : 'normal',
                        ...headerConfig.attributes?.style,
                      }}
                    >
                      {(() => {
                        switch (typeof item[headerConfig.propName]) {
                          case 'boolean':
                            return `${item[headerConfig.propName]}`
                          default:
                            return (
                              <span
                                {...(typeof item[headerConfig.propName] === 'string' ||
                                  (typeof item[headerConfig.propName] === 'number'
                                    ? {
                                        title: item[headerConfig.propName],
                                      }
                                    : {}))}
                                className="table-td"
                              >
                                {item[headerConfig.propName]}
                              </span>
                            )
                        }
                      })()}
                    </Td>
                  ))
                ) : (
                  <></>
                )}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td style={{ textAlign: 'center' }} colSpan={headersConfig.length}>
                {emptyListMessage || 'Não foram fornecidos itens para a lista'}
              </Td>
            </Tr>
          )}
        </Tbody>
      </StyledTable>
      {usePagination && numberOfPages > 1 && (
        <DefaultTablePagination>
          <DefaultButton
            className="medium white previous"
            onClick={() => changeCurrentPage(currentPage - 1)}
            disabled={currentPage <= 0}
          >
            <AiOutlineLeft />
          </DefaultButton>
          {Array(Math.min(5, numberOfPages))
            .fill('')
            .map((_, index) => (
              <DefaultButton
                key={index}
                className="white medium element"
                onClick={() => changeCurrentPage(currentPage + index)}
                style={
                  currentPage === currentPage + index
                    ? {
                        background: 'var(--primary-color)',
                        color: 'white',
                        borderColor: 'var(--primary-color)',
                      }
                    : {}
                }
                disabled={currentPage + (index + 1) > numberOfPages}
              >
                {currentPage + index + 1}
              </DefaultButton>
            ))}
          <DefaultButton
            className="medium white next"
            onClick={() => changeCurrentPage(currentPage + 1)}
            disabled={currentPage >= numberOfPages - 1}
          >
            <AiOutlineRight />
          </DefaultButton>
        </DefaultTablePagination>
      )}
    </>
  )
}

export default Table
