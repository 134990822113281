import styled from 'styled-components'

const PageTitle = styled.h2`
  font-size: var(--font-medium);
  margin-bottom: 30px;
  border-bottom: solid 1px var(--border-hr);
  color: var(--default-title-color);
  font-weight: 400;
  padding-bottom: 10px;
`

export default PageTitle
