import httpClient from 'http-client'
import Banner from 'models/banner'
import BannerForCreate from 'models/for-create/banner'
import BannerForUpdate from 'models/for-update/banner'

export const getBanner = async (bannerId: string): Promise<Banner> => {
  const banner = (await httpClient.get(`banners/${bannerId}`)).data

  return banner
}

export const getAllBanners = async (): Promise<Banner[]> => {
  const banners = (await httpClient.get('banners')).data

  return banners
}

export const createBanner = async (newBanner: BannerForCreate): Promise<Banner> => {
  const createBannerResponse = (await httpClient.post(`banners`, newBanner)).data

  return createBannerResponse
}

export const updateBanner = async (
  bannerId: string,
  newContent: BannerForUpdate
): Promise<void> => {
  await httpClient.put(`banners/${bannerId}`, newContent)
}

export const deleteBanner = async (bannerId: string): Promise<void> => {
  await httpClient.delete(`banners/${bannerId}`)
}

export const activateBanner = async (bannerId: string): Promise<void> => {
  await httpClient.patch(`banners/${bannerId}`)
}
