import styled from 'styled-components'
import Select from 'react-select'

const SelectComponent = styled(Select).attrs(() => ({
  styles: {
    container: (provided: any) => ({
      ...provided,
      flexGrow: 1,
      width: '100%',
      fontSize: '1.4rem',
      color: 'white',
    }),
    control: (provided: any) => ({
      ...provided,
      background: 'transparent',
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#1D1D1B',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: '45px',
    }),
    option: (provided: any) => ({
      ...provided,
      '&:hover': {
        color: 'black',
      },
    }),
    multiValue: (provided: any) => ({
      ...provided,
      color: 'red',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      background: '#E09321',
      color: 'black',
    }),
  },
  theme: (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#E09321',
      primary25: '#E09321',
    },
  }),
}))``

export default SelectComponent
