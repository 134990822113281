import styled from 'styled-components'

const TextArea = styled.textarea`
  border-radius: 5px;
  outline: none;
  padding: 10px 15px;
  font-size: var(--font-small);
  color: var(--white);
  width: 100%;
  resize: vertical;
  background: transparent;
  border: 1px solid var(--white);
  transition: border-color 0.3s;

  &:focus {
    border-color: var(--primary-color);
  }

  &:disabled {
    border-color: var(--gray);
  }
`

export default TextArea
