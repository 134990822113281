import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import { Link, useLocation, useHistory } from 'react-router-dom'
import FormGroup from 'components/FormGroup'
import { Buttons, Container, Date, UserName } from '../style'
import BreadCrumb from 'components/BreadCrumb'
import PageTitle from 'components/PageTitle'
import Select from 'components/Select'
import { getAllPlans } from 'services/plans'
import Plan from 'models/plan'
import { FormButtons } from 'pages/Categories/CreateAndEdit/style'
import Button from 'components/Button'
import { updateUserPlan } from 'services/users'

const ActiveUser: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const user = location.state as any
  const [plans, setPlans] = useState<Plan[]>()
  const [selectedPlans, setSelectedPlans] = useState<{ label: string; value: string }[]>([])
  const [finishDate, setFinishDate] = useState<any>()

  const getPlans = async () => {
    const foundPlans = await getAllPlans()
    foundPlans && setPlans(foundPlans)
  }
  useEffect(() => {
    getPlans()
  }, [])

  const plansToBeSelected = useMemo(() => {
    if (plans && plans.length) {
      return plans.map((plan) => ({
        label: plan.title,
        value: `${plan.plan_id}`,
      }))
    }
    return [] as { label: string; value: string }[]
  }, [plans])

  const updateUserPlanAccess = async (): Promise<void> => {
    const planId = selectedPlans.map((item) => {
      return item.value
    })

    try {
      if (!planId || !planId[0]) {
        throw new Error('Selecione um plano.')
      }
      if (!finishDate) {
        throw new Error('Informe  a data de fim da assinatura.')
      }

      const data = {
        plan_id: planId[0],
        user_id: user?.user?.user_id,
        finish_date: `${finishDate} 00:00:00`,
      }
      const res = await updateUserPlan(data)
      if (res) {
        Swal.fire({
          title: 'Sucesso',
          text: 'Plano do usuário ativado com sucesso!',
          icon: 'success',
        })
        history.push('/users')
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao ativar usuário. ' + error.message,
        icon: 'error',
      })
    }
  }

  return (
    <>
      <Container>
        <BreadCrumb
          crumbs={[
            <Link key={1} to="/home">
              Início
            </Link>,
            <span key={2}>Usuários</span>,
          ]}
        />
        <PageTitle>Ativar Usuário</PageTitle>
        <FormGroup>
          <label htmlFor="nome">Usuário</label>
          <UserName>{user?.user.name}</UserName>
        </FormGroup>
        <FormGroup>
          <label htmlFor="plans">Planos</label>
          <Select
            id="plans"
            options={plansToBeSelected}
            value={selectedPlans}
            onChange={(options: any) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedPlans(
                options.map((opt: any) => ({
                  label: opt.label,
                  value: opt.value,
                }))
              )
            }
            isMulti
            placeholder="Selecione plano para o usuário."
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="date">Fim da assinatura</label>
          <Date>
            <input id="date" type="date" onChange={(e) => setFinishDate(e.target.value)} />
          </Date>
        </FormGroup>
        <Buttons>
          <FormButtons>
            <Button type="button" className="danger">
              Cancelar
            </Button>
            <Button className="success" onClick={() => updateUserPlanAccess()}>
              Salvar
            </Button>
          </FormButtons>
        </Buttons>
      </Container>
    </>
  )
}
export default ActiveUser
