import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import Switch from 'react-switch'

import { BiEdit, BiTrash } from 'react-icons/bi'

import formatPrice from 'helpers/format-price'
import Plan from 'models/plan'
import {
  activateOrInactivatePlan as activateOrInactivatePlanService,
  getAllPlans,
  deletePlan,
} from 'services/plans'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Box from 'components/Box'

import { Container } from './style'

const Plans: React.FC = () => {
  const history = useHistory()

  const [plans, setPlans] = useState([] as Plan[])

  const getPlans = async (): Promise<void> => {
    const localPlans = await getAllPlans()
    if (localPlans && localPlans.length) {
      setPlans(localPlans)
    }
  }

  const editPlan = useCallback(
    (planId: string) => {
      history.push(`edit-plan/${planId}`)
    },
    [history]
  )

  const removePlan = useCallback(async (planId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este plano?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deletePlan(planId)

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Plano excluido com sucesso!',
          })

          setPlans(plans.filter((item) => item.plan_id !== planId))

          await getPlans()
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir plano. ${
              error.response && error.response.status === 423
                ? 'Este plano já está associado a um curso!'
                : error.message
            }`,
          })
        }
      }
    })
  }, [])

  const createPlan = (): void => {
    history.push('create-plan')
  }

  const activateOrInactivatePlan = useCallback(async (plan: Plan) => {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja ${!plan.is_active ? 'ativar' : 'inativar'} este plano?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateOrInactivatePlanService(plan)

          setPlans(
            plans.map((item) => {
              if (item.plan_id === plan.plan_id) {
                item.is_active = !item.is_active
              }
              return item
            })
          )

          getPlans()
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: `Erro ao ${!plan.is_active ? 'ativar' : 'inativar'} plano. ${
              error.response && error.response.status === 423
                ? 'Este plano já está associado a um curso!'
                : error.message
            }`,
          })
        }
      }
    })
  }, [])

  const contentsToBeShown = useMemo(() => {
    return plans && plans.length
      ? plans.map((plan) => ({
          id: plan.plan_id,
          title: plan.title,
          description: JSON.parse(plan.description).length,
          price: formatPrice(plan.price),
          active: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
              title={plan.is_active ? 'Tornar Inativo' : 'Tornar Ativo'}
            >
              <Switch
                onChange={() => activateOrInactivatePlan(plan)}
                checked={plan.is_active || false}
              />
            </div>
          ),

          actions: (
            <>
              <Button
                onClick={() => editPlan(plan.plan_id)}
                className="small info"
                title="Editar Plano"
              >
                <BiEdit />
              </Button>{' '}
              <Button
                onClick={() => {
                  removePlan(plan.plan_id)
                }}
                className="small danger"
                title="Excluir Plano"
              >
                <BiTrash />
              </Button>
            </>
          ),
        }))
      : []
  }, [plans, editPlan, removePlan, activateOrInactivatePlan])

  useEffect(() => {
    getPlans()
  }, [])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Planos</span>,
        ]}
      />

      <PageTitle>Planos</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createPlan}>Criar Plano</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Vantagens</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Preço</span>,
            propName: 'price',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados planos cadastradas!'}
      />
    </Container>
  )
}

export default Plans
