import styled from 'styled-components'

const DefaultButton = styled.button`
  background: none;
  border: 1px solid #e9ecef;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8898aa;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;

  &.previous {
    margin-right: 21px;
    margin-left: 0;
  }

  &.next {
    margin-right: 0;
    margin-left: 21px;
  }

  &.element + &.element {
    margin-left: 10px;
  }

  &.element:disabled {
    display: none;
  }
`

export default DefaultButton
