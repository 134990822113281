import httpClient from 'http-client'
import Photo from 'models/photo'
import PhotoForCreate from 'models/for-create/photo'
import PhotoForUpdate from 'models/for-update/photo'

export const getPhoto = async (photoId: string): Promise<Photo> => {
  const photo = (await httpClient.get(`photos/${photoId}`)).data

  return photo
}

export const getAllPhotos = async (): Promise<Photo[]> => {
  const photos = (await httpClient.get('photos')).data

  return photos
}

export const createPhoto = async (newPhoto: PhotoForCreate): Promise<Photo> => {
  const createPhotoResponse = (await httpClient.post(`photos`, newPhoto)).data

  return createPhotoResponse
}

export const updatePhoto = async (photoId: string, newContent: PhotoForUpdate): Promise<void> => {
  await httpClient.put(`photos/${photoId}`, newContent)
}

export const deletePhoto = async (photoId: string): Promise<void> => {
  await httpClient.delete(`photos/${photoId}`)
}
