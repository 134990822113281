import httpClient from '../http-client/index'

interface IOperationResult {
  successMessage?: string
  errorMessage?: string
  token?: string | undefined
  error?: string
}

const login = async (email: string, password: string): Promise<IOperationResult> => {
  let token = ''
  try {
    await httpClient
      .post('/session/login', {
        email,
        password,
      })
      .then((response) => {
        token = response.data.api_token
        window.localStorage.setItem('sda-api-token', token)
      })
  } catch (error) {
    return Promise.resolve({
      error,
    })
  }
  return Promise.resolve({
    token,
  })
}

export { login }
