import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import logo from 'assets/logo.png'
import { login as loginService } from 'services/login'

import * as Style from './style'

const Login: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const history = useHistory()

  const login = (event: React.FormEvent) => {
    event.preventDefault()

    loginService(email, password).then((resposta) => {
      if (resposta.token) {
        history.push('/home')
      } else {
        Swal.fire({
          title: 'Erro',
          text: 'Dados de login incorretos!',
          icon: 'error',
        })
      }
    })
  }

  return (
    <Style.Container>
      <Style.LogoContainer>
        <picture>
          <source media="(max-width:768px)" srcSet={logo} />
          <img src={logo} alt="Logo" />
        </picture>
      </Style.LogoContainer>
      <Style.LoginContainer>
        <Style.LoginBlock>
          <Style.LoginTitle>Que bom te ver aqui!</Style.LoginTitle>
          <Style.LoginDescription>
            Seja bem vinde a <strong>plataforma administrativa</strong> da Segredos de Ana.
          </Style.LoginDescription>
          <Style.LoginForm onSubmit={login}>
            <Style.LoginLabel>
              Login
              <Style.LoginInput
                type="email"
                placeholder="Digite seu e-mail"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Style.LoginLabel>
            <Style.LoginLabel>
              Senha
              <Style.LoginInput
                type="password"
                placeholder="Senha"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Style.LoginLabel>
            <Style.LoginInput type="submit" value="Entrar" />
          </Style.LoginForm>
        </Style.LoginBlock>
      </Style.LoginContainer>
    </Style.Container>
  )
}

export default Login
