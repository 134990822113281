import httpClient from 'http-client'
import Plan from 'models/plan'
import PlanForCreate from 'models/for-create/plan'
import PlanForUpdate from 'models/for-update/plan'

export const getPlan = async (planId: string): Promise<Plan> => {
  const plan = (await httpClient.get(`plan/${planId}`)).data

  return plan
}

export const getAllPlans = async (): Promise<Plan[]> => {
  const plans = (await httpClient.get('plan')).data

  return plans
}

export const createPlan = async (newPlan: PlanForCreate): Promise<Plan> => {
  const createPlanResponse = (await httpClient.post(`plan`, newPlan)).data

  return createPlanResponse
}

export const updatePlan = async (planId: string, newContent: PlanForUpdate): Promise<void> => {
  await httpClient.put(`plan/${planId}`, newContent)
}

export const deletePlan = async (planId: string): Promise<void> => {
  await httpClient.delete(`plan/${planId}`)
}

export const activateOrInactivatePlan = async (plan: Plan): Promise<void> => {
  await httpClient.patch(`plan/${plan.plan_id}/active`, { is_active: !plan.is_active })
}
