import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { BiEdit, BiTrash } from 'react-icons/bi'

import Category from 'models/category'
import {
  activateOrInactivateCategory as activateOrInactivateCategoryService,
  getAllCategories,
  deleteCategory,
} from 'services/categories'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Box from 'components/Box'

import { Container } from './style'

const Categories: React.FC = () => {
  const history = useHistory()

  const [categories, setCategories] = useState([] as Category[])

  const getCategories = async (): Promise<void> => {
    const localCategories = await getAllCategories()
    if (localCategories && localCategories.length) {
      setCategories(localCategories)
    }
  }

  const editCategory = useCallback(
    (categoryId: string) => {
      history.push(`edit-category/${categoryId}`)
    },
    [history]
  )

  const removeCategory = useCallback(async (categoryId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta categoria?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCategory(categoryId)

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Categoria excluida com sucesso!',
          })

          await getCategories()
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir categoria. ${
              error.response && error.response.status === 423
                ? 'Esta categoria já está associada a um curso!'
                : error.message
            }`,
          })
        }
      }
    })
  }, [])

  const createCategory = (): void => {
    history.push('create-category')
  }

  const activateOrInactivateCategory = useCallback(
    async (categoryId: string, activate: boolean) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} esta categoria?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCategoryService(categoryId, activate)

            getCategories()
          } catch (error) {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao ${activate ? 'ativar' : 'inativar'} categoria. ${
                error.response && error.response.status === 423
                  ? 'Esta categoria já está associado a um curso!'
                  : error.message
              }`,
            })
          }
        }
      })
    },
    []
  )

  const contentsToBeShown = useMemo(() => {
    return categories && categories.length
      ? categories.map((category) => ({
          id: category.category_id,
          title: category.title,
          description: category.description,
          active: category.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <>
              <Button
                onClick={() => editCategory(category.category_id)}
                className="small info"
                title="Editar Categoria"
              >
                <BiEdit />
              </Button>{' '}
              <Button
                onClick={() =>
                  activateOrInactivateCategory(category?.category_id, !category.is_active)
                }
                className="small warning"
                title={(category.is_active ? 'Inativar' : 'Ativar') + ' Conteúdo'}
              >
                {category.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </Button>{' '}
              <Button
                onClick={() => {
                  removeCategory(category.category_id)
                }}
                className="small danger"
                title="Excluir Categoria"
              >
                <BiTrash />
              </Button>
            </>
          ),
        }))
      : []
  }, [categories, editCategory, removeCategory, activateOrInactivateCategory])

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Categorias</span>,
        ]}
      />

      <PageTitle>Categorias</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createCategory}>Criar Categoria</Button>
      </Box>

      <Table
        usePagination={true}
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados categorias cadastradas!'}
      />
    </Container>
  )
}

export default Categories
