import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

import Photo from 'models/photo'
import generateThumbnail from 'helpers/generate-thumbnail'
import checkEmptyString from 'helpers/check-empty-string'
import { hideModal, showModal } from 'helpers/modal'
import {
  createAlbum as createAlbumService,
  getAlbum as getAlbumService,
  updateAlbum as updateAlbumService,
} from 'services/albums'
import { uploadFile } from 'services/files'
import { createPhoto } from 'services/photos'

import Form from 'components/Form'
import FormGroup from 'components/FormGroup'
import Input from 'components/Input'
import InputPhotos, { FileWithSrc } from 'components/InputPhotos'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import CutImage from 'components/CutImage'

import { Container, ContentThumbnail, ThumbnailUploadContainer, FormButtons } from './style'
import Select from 'components/Select'
import { getAllPlans } from 'services/plans'
import Plan from 'models/plan'

interface CreateAndEditAlbumProps {
  albumId: string
}

const CreateAndEditAlbum: React.FC = () => {
  const { albumId } = useParams<CreateAndEditAlbumProps>()

  const history = useHistory()

  const [title, setTitle] = useState('')
  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
  const [thumbnailLargeFileToUpload, setThumbnailLargeFileToUpload] = useState<File>()
  const [thumbnail, setThumbnail] = useState('')
  const [thumbnailLarge, setThumbnailLarge] = useState('')
  const [photos, setPhotos] = useState<FileWithSrc[]>([])
  const [photosFromAlbum, setPhotosFromAlbum] = useState<Photo[]>([])

  const [plans, setPlans] = useState<Plan[]>([])
  const [selectedPlans, setSelectedPlans] = useState<{ label: string; value: string }[]>([])

  const plansToBeSelected = useMemo(() => {
    if (plans && plans.length) {
      return plans.map((plan) => ({
        label: plan.title,
        value: `${plan.plan_id}`,
      }))
    }
  }, [plans])

  const createAlbum = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o álbum.')
      }

      if (!thumbnailFileToUpload || !thumbnailLargeFileToUpload) {
        throw new Error('Selecione uma imagem de miniatura.')
      }

      const thumbnailReference = await getImageReference(thumbnailFileToUpload)
      const thumbnailLargeReference = await getImageReference(thumbnailLargeFileToUpload)

      const photosReferences = await Promise.all(
        photos.map((photo) => getImageReference(photo.file))
      )

      const photosFromDb = await Promise.all(
        photosReferences.map((photoReference) => createPhoto({ file: photoReference }))
      )

      await createAlbumService({
        name: title,
        thumbnail: thumbnailReference,
        thumbnail_large: thumbnailLargeReference,
        photos: photosFromDb.map((photo) => ({ photo_id: photo.photo_id })),
        plans: selectedPlans.map(({ value: plan_id }) => ({ plan_id })),
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Álbum criado com sucesso!',
        icon: 'success',
      })

      goToAlbums()
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o álbum. ' + error.message,
        icon: 'error',
      })
    }
  }

  const updateAlbum = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o álbum.')
      }

      if (!thumbnail && !thumbnailFileToUpload) {
        throw new Error('Selecione uma imagem de miniatura.')
      }

      const resolveThumbnail = async (): Promise<string> => {
        if (thumbnailFileToUpload) return await getImageReference(thumbnailFileToUpload)
        return thumbnail
      }

      const resolveThumbnailLarge = async (): Promise<string> => {
        if (thumbnailLargeFileToUpload) return await getImageReference(thumbnailLargeFileToUpload)
        return thumbnailLarge
      }

      const photosToUpdate = await Promise.all(
        photos.map(async (photo) => {
          const photoFound = photosFromAlbum.find(
            (photoFromAlbum) => photoFromAlbum.file === photo.imgSrc
          )

          if (!photoFound) {
            const photoReference = await getImageReference(photo.file)
            const createdPhoto = await createPhoto({ file: photoReference })

            return { photo_id: createdPhoto.photo_id }
          }

          return { photo_id: photoFound.photo_id }
        })
      )

      await updateAlbumService(albumId, {
        name: title,
        thumbnail: await resolveThumbnail(),
        thumbnail_large: await resolveThumbnailLarge(),
        photos: photosToUpdate,
        plans: selectedPlans.map(({ value: plan_id }) => ({ plan_id })),
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo editado com sucesso!',
        icon: 'success',
      })

      goToAlbums()
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o conteúdo. ' + error.message,
        icon: 'error',
      })
    }
  }

  const getImageReference = async (imageFile: File): Promise<string> => {
    try {
      return (await uploadFile(imageFile)).reference
    } catch {
      throw new Error(
        'Erro ao fazer upload da imagem de miniatura. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
      )
    }
  }

  const selectThumbnail = (): void => {
    showModal('Selecionar Imagem de Miniatura', <CutImage aspect={1.812} onCutImage={onCutImage} />)
  }

  const onCutImage = (file: File): void => {
    if (file) {
      generateThumbnail({
        file,
        maxWidth: 375,
        callback: (thumbnail) => setThumbnailFileToUpload(thumbnail),
      })
      generateThumbnail({
        file,
        maxWidth: 1200,
        callback: (thumbnail) => setThumbnailLargeFileToUpload(thumbnail),
      })

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setThumbnail(`${reader.result}`)

      hideModal()
    }
  }

  const goToAlbums = (): void => {
    history.push('/albums')
  }

  const getAlbum = useCallback(async () => {
    const foundPlans = await getAllPlans()
    foundPlans && setPlans(foundPlans)

    if (albumId) {
      const album = await getAlbumService(albumId)
      if (album && Object.keys(album).length) {
        setTitle(album.name)
        setThumbnail(album.thumbnail)
        setThumbnailLarge(album.thumbnail_large)

        if (album.photos?.length) {
          const albumPhotos = await Promise.all(
            album.photos.map(async (photo) => {
              const imageResponse = await fetch(photo.file, { mode: 'no-cors' })
              const blob = await imageResponse.blob()
              const file = new File([blob], photo.photo_id, { type: blob.type })

              return {
                file,
                imgSrc: photo.file,
              }
            })
          )

          setPhotosFromAlbum(album.photos)
          setPhotos(albumPhotos)

          const selectedPlans = album.plans.map((plan: Plan) => ({
            label: plan.title,
            value: plan.plan_id,
          }))
          setSelectedPlans(selectedPlans)
        }
      }
    }
  }, [albumId])

  useEffect(() => {
    getAlbum()
  }, [getAlbum])

  const isEditting = useMemo(() => {
    if (albumId) {
      return true
    }

    return false
  }, [albumId])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <Link key={2} to="/albums">
            Álbuns
          </Link>,
          <span key={3}>{isEditting ? 'Editar' : 'Criar'} Álbum</span>,
        ]}
      />

      <PageTitle>{isEditting ? 'Editar' : 'Criar'} Álbum</PageTitle>

      <Form>
        <FormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} id="title" required />
        </FormGroup>

        <FormGroup>
          <label htmlFor="tags">Planos</label>
          <Select
            id="plans"
            options={plansToBeSelected}
            value={selectedPlans}
            onChange={(options: any) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedPlans(
                options.map((opt: any) => ({
                  label: opt.label,
                  value: opt.value,
                }))
              )
            }
            isMulti
            placeholder="Selecione planos para os quais este álbum estará disponível."
          />
        </FormGroup>

        <FormGroup>
          <label className="required" htmlFor="photos">
            Fotos
          </label>

          <InputPhotos name="photos" photos={photos} setPhotos={setPhotos} />
        </FormGroup>

        <FormGroup>
          <label htmlFor="reference" className="required">
            Miniatura
          </label>

          <ThumbnailUploadContainer>
            <Button type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Miniatura
            </Button>

            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </FormGroup>

        <FormButtons>
          <Button type="button" className="danger" onClick={goToAlbums}>
            Cancelar
          </Button>
          <Button
            onClick={(e) => (isEditting ? updateAlbum(e) : createAlbum(e))}
            className="success"
          >
            Salvar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  )
}

export default CreateAndEditAlbum
