import React from 'react'
import { BsMusicNoteBeamed, BsFillPlayFill } from 'react-icons/bs'

import MediaSambaVideos from 'models/media'
import convertSecondsToTimeText from 'helpers/seconds-to-time-text'

import {
  SambaVideosContentCardContainer,
  SambaVideosContentDuration,
  SambaVideosContentTextContent,
  SambaVideosContentThumb,
  SambaVideosContentThumbContainer,
  SambaVideosContentTitle,
} from './style'

interface SambaVideosContentCardProps {
  content: MediaSambaVideos
  onClick: (content: MediaSambaVideos) => void
}

const SambaVideosContentCard: React.FC<SambaVideosContentCardProps> = ({ content, onClick }) => {
  const maxSizedThumbUrl =
    content && content.thumbs && content.thumbs.length
      ? content.thumbs.sort((thumbA, thumbB) => (thumbA.width > thumbB.width ? -1 : 1))[0].url
      : ''

  const maxSizedFile =
    content &&
    content.files &&
    content.files.length &&
    content.files.sort((thumbA, thumbB) => (thumbA.fileSize > thumbB.fileSize ? -1 : 1))[0]

  return (
    <SambaVideosContentCardContainer onClick={() => onClick(content)}>
      <SambaVideosContentThumbContainer>
        {maxSizedThumbUrl ? (
          <SambaVideosContentThumb src={maxSizedThumbUrl} />
        ) : maxSizedFile && maxSizedFile.qualifier === 'VIDEO' ? (
          <BsFillPlayFill size={32} />
        ) : (
          <BsMusicNoteBeamed size={32} />
        )}
      </SambaVideosContentThumbContainer>
      <SambaVideosContentTextContent>
        <SambaVideosContentTitle>{content.title}</SambaVideosContentTitle>
        <SambaVideosContentDuration>
          {maxSizedFile &&
            maxSizedFile.fileInfo &&
            convertSecondsToTimeText(maxSizedFile.fileInfo.duration / 1000)}
        </SambaVideosContentDuration>
      </SambaVideosContentTextContent>
    </SambaVideosContentCardContainer>
  )
}

export default SambaVideosContentCard
