import styled from 'styled-components'

export const DefaultTablePagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 50px 0 0;
`

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const Thead = styled.thead`
  background: #444;
`

export const Tbody = styled.tbody`
  > tr:hover {
    background: #222;
  }
`

export const Tr = styled.tr`
  color: var(--white);
  td:first-of-type {
    color: var(--primary-color);
    font-weight: 700;
  }
`

export const Th = styled.th`
  font-size: 14px;
  color: var(--white);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.9px;
  padding: 16px 10px;
  &:last-of-type,
  &:nth-last-child(-n + 2) {
    text-align: center;
  }
`

export const Td = styled.td`
  padding: 16px;
  font-size: var(--font-small);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  svg {
    display: block;
    margin: 0 auto;
  }
  > img {
    width: 120px !important;
  }

  &:last-of-type,
  &:nth-last-child(-n + 2) {
    text-align: center;
  }
`
