import styled from 'styled-components'

export const Container = styled.div``

export const PerksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;

  & > div:last-child {
    display: flex;
    gap: 16px;

    > div {
      font-size: 16px;
      color: var(--white);
      background: var(--primary-color);
      border-radius: 4px;
      display: flex;
      overflow: hidden;

      button {
        width: 30px;
        font-size: 24px;
        color: var(--white);
        background: var(--secondary-color);
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      span {
        padding: 8px;
      }
    }
  }

  & > div:first-child {
    > div {
      display: flex;
      width: 100%;

      input {
        border-radius: 4px 0 0 4px;
      }

      button {
        width: 50px;
        color: var(--white);
        border-radius: 0 4px 4px 0;
        border: 1px solid var(--primary-color);
        background: var(--primary-color);
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
`

export const FormButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`
export const ThumbnailUploadContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  img {
    margin-top: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ContentThumbnail = styled.img`
  max-width: 600px;
  background-color: #ebebeb;
`
export const Title = styled.p`
  margin-bottom: 10px;
  font-size: var(--font-small);
  color: var(--white);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
