import styled from 'styled-components'

export const Container = styled.div``

export const ThumbnailUploadContainer = styled.div`
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`

export const ContentThumbnail = styled.div`
  margin-top: 10px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  > div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: ease-in 0.2s all;

    button {
      opacity: 0;
      background: none;
      color: var(--white);
      font-size: 18px;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: ease-in 0.2s all;

      svg {
        font-size: 32px;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.5);

      button {
        opacity: 1;
      }
    }
  }

  img {
    max-height: 300px;
    background-color: #ebebeb;
  }
`

export const FormButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`
