import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  input {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
  }
`

export const PhotoList = styled.div`
  display: flex;
  padding-top: 20px;
`

export const PhotoPreview = styled.div`
  position: relative;
  margin: 0 10px;

  img {
    width: 150px;
    height: 150px;
  }
`

export const RemovePhoto = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: var(--danger-color);
  outline: none;
  cursor: pointer;

  svg {
    width: 13px;
    height: 13px;
  }

  &:hover {
    svg path {
      stroke: white;
    }
  }
`
