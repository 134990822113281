import React, { useCallback, useState } from 'react'
import { LogoContainer, LogoutButton, Menus, SideBarContainer } from './style'
import { Link, useHistory, useLocation } from 'react-router-dom'
import menus from './data/menus'
import { BiLogOut } from 'react-icons/bi'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'

import logo from 'assets/logo.png'

const SideBar: React.FC = () => {
  const history = useHistory()
  const location = useLocation()

  const [menuChildrenToBeShown, setMenuChildrenToBeShown] = useState<number[]>([])

  const showOrHideMenuChildren = (index: number) => {
    if (menuChildrenToBeShown.includes(index)) {
      const indexOfMenu = menuChildrenToBeShown.indexOf(index)
      if (indexOfMenu > -1 && indexOfMenu < menuChildrenToBeShown.length) {
        menuChildrenToBeShown.splice(indexOfMenu, 1)
      }
    } else {
      menuChildrenToBeShown.push(index)
    }

    setMenuChildrenToBeShown([...menuChildrenToBeShown])
  }

  const checkShouldShowMenuChildren = useCallback(
    (index: number) => {
      return menuChildrenToBeShown.includes(index)
    },
    [menuChildrenToBeShown]
  )

  const logout = (): void => {
    localStorage.clear()
    history.push('/login')
  }

  return (
    <SideBarContainer>
      <LogoContainer>
        <img src={logo} alt="logo" />
      </LogoContainer>
      <Menus>
        {menus && menus.length ? (
          menus.map((menu, index) =>
            menu.children && menu.children.length ? (
              <div key={index}>
                <button onClick={() => showOrHideMenuChildren(index)}>
                  {menu.label}
                  <span
                    className={`sub-items ${menuChildrenToBeShown.includes(index) ? 'active' : ''}`}
                  >
                    <Arrow />
                  </span>
                </button>
                <div style={{ marginLeft: '25px' }}>
                  {checkShouldShowMenuChildren(index) ? (
                    menu.children.map((child) => (
                      <Link
                        className={location.pathname === child.path ? 'active' : ''}
                        key={child.path}
                        to={child.path}
                      >
                        {child.label}
                      </Link>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : menu.path ? (
              <Link
                className={location.pathname === menu.path ? 'active' : ''}
                key={menu.path}
                to={menu.path}
              >
                {menu.label}
              </Link>
            ) : (
              <></>
            )
          )
        ) : (
          <></>
        )}

        <LogoutButton onClick={logout}>
          <span className="icon">
            <BiLogOut />
          </span>
          <span className="text">Logout</span>
        </LogoutButton>
      </Menus>
    </SideBarContainer>
  )
}

export default SideBar
