import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import Switch from 'react-switch'

import { BiEdit, BiTrash } from 'react-icons/bi'

import Album from 'models/album'
import { updateStatusAlbum, getAllAlbums, deleteAlbum } from 'services/albums'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Box from 'components/Box'

import { Container } from './style'

const Albums: React.FC = () => {
  const history = useHistory()

  const [albums, setAlbums] = useState([] as Album[])

  const getAlbums = async (): Promise<void> => {
    const localAlbums = await getAllAlbums()
    if (localAlbums && localAlbums.length) {
      setAlbums(localAlbums)
    }
  }

  const editAlbum = useCallback(
    (albumId: string) => {
      history.push(`edit-album/${albumId}`)
    },
    [history]
  )

  const updateStatus = useCallback(
    async (album: Album) => {
      try {
        await updateStatusAlbum(album.album_id, !album.is_active)

        setAlbums(
          albums.map((item) => {
            if (item.album_id === album.album_id) {
              item.is_active = !album.is_active
            }

            return item
          })
        )
      } catch (err) {
        console.log(err)
      }
    },
    [albums]
  )

  const removeAlbum = useCallback(async (albumId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este álbum?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteAlbum(albumId)

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Álbum excluído com sucesso!',
          })

          await getAlbums()
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir álbum. ${
              error.response && error.response.status === 423
                ? 'Este álbum já está associado a um curso!'
                : error.message
            }`,
          })
        }
      }
    })
  }, [])

  const createAlbum = (): void => {
    history.push('create-album')
  }

  const contentsToBeShown = useMemo(() => {
    return albums && albums.length
      ? albums.map((album) => ({
          id: album.album_id,
          name: album.name,
          isActive: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
              }}
              title={album.is_active ? 'Inativar Álbum' : 'Ativar Álbum'}
            >
              <Switch onChange={() => updateStatus(album)} checked={album.is_active || false} />
            </div>
          ),
          actions: (
            <>
              <Button
                onClick={() => editAlbum(album.album_id)}
                className="small info"
                title="Editar Álbum"
              >
                <BiEdit />
              </Button>{' '}
              <Button
                onClick={() => {
                  removeAlbum(album.album_id)
                }}
                className="small danger"
                title="Excluir Álbum"
              >
                <BiTrash />
              </Button>
            </>
          ),
        }))
      : []
  }, [albums, editAlbum, removeAlbum, updateStatus])

  useEffect(() => {
    getAlbums()
  }, [])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Álbuns</span>,
        ]}
      />

      <PageTitle>Álbuns</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createAlbum}>Criar Álbum</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'isActive',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados álbums cadastrados!'}
      />
    </Container>
  )
}

export default Albums
