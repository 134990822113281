import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Users from 'pages/Users'
import Profile from 'pages/Profile'

import Categories from 'pages/Categories'
import CreateAndEditCategory from 'pages/Categories/CreateAndEdit'

import Videos from 'pages/Videos'
import CreateAndEditVideo from 'pages/Videos/CreateAndEdit'

import Albums from 'pages/Albums'
import CreateAndEditAlbum from 'pages/Albums/CreateAndEdit'

import Perks from 'pages/Perks'
import CreateAndEditPerk from 'pages/Perks/CreateAndEdit'

import Plans from 'pages/Plans'
import CreateAndEditPlan from 'pages/Plans/CreateAndEdit'

import Banners from 'pages/Banners'
import CreateAndEditBanner from 'pages/Banners/CreateAndEdit'

import Terms from 'pages/Terms'
import Politics from 'pages/Politics'
import ActiveUser from 'pages/Users/active'

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/home" component={Profile} />

    <Route exact path="/users" component={Users} />
    <Route exact path="/user/active/:id" component={ActiveUser} />

    <Route exact path="/categories" component={Categories} />
    <Route exact path="/create-category" component={CreateAndEditCategory} />
    <Route exact path="/edit-category/:categoryId" component={CreateAndEditCategory} />

    <Route exact path="/plans" component={Plans} />
    <Route exact path="/create-plan" component={CreateAndEditPlan} />
    <Route exact path="/edit-plan/:planId" component={CreateAndEditPlan} />

    <Route exact path="/videos" component={Videos} />
    <Route exact path="/create-video" component={CreateAndEditVideo} />
    <Route exact path="/edit-video/:contentId" component={CreateAndEditVideo} />

    <Route exact path="/albums" component={Albums} />
    <Route exact path="/create-album" component={CreateAndEditAlbum} />
    <Route exact path="/edit-album/:albumId" component={CreateAndEditAlbum} />

    <Route exact path="/perks" component={Perks} />
    <Route exact path="/create-perk" component={CreateAndEditPerk} />
    <Route exact path="/edit-perk/:perkId" component={CreateAndEditPerk} />

    <Route exact path="/banners" component={Banners} />
    <Route exact path="/create-banner" component={CreateAndEditBanner} />
    <Route exact path="/edit-banner/:bannerId" component={CreateAndEditBanner} />

    <Route exact path="/terms" component={Terms} />
    <Route exact path="/politics" component={Politics} />
  </Switch>
)

export default Routes
