import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { BiEdit, BiTrash } from 'react-icons/bi'

import Perk from 'models/perk'

import { getAllPerks, deletePerk, activateOrInactivatePerk } from 'services/perks'

import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import Table from 'components/Table'
import Box from 'components/Box'

import { Container } from './style'

const Perks: React.FC = () => {
  const history = useHistory()

  const [perks, setPerks] = useState([] as Perk[])

  const getPerks = async (): Promise<void> => {
    const localPerks = await getAllPerks()
    if (localPerks && localPerks.length) {
      setPerks(localPerks)
    }
  }

  const editPerk = useCallback(
    (perkId: string) => {
      history.push(`edit-perk/${perkId}`)
    },
    [history]
  )

  const removePerk = useCallback(async (perkId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este conteúdo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deletePerk(perkId)

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Conteúdo excluido com sucesso!',
          })

          await getPerks()
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir conteúdo. ${
              error.response && error.response.status === 423
                ? 'Este conteúdo já está associado a um curso!'
                : error.message
            }`,
          })
        }
      }
    })
  }, [])

  const createPerk = (): void => {
    history.push('create-perk')
  }

  const toggleActivePerk = useCallback(async (perkId: string, activate: boolean) => {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} este conteúdo?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateOrInactivatePerk(perkId, activate)

          getPerks()
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: `Erro ao ${activate ? 'ativar' : 'inativar'} conteúdo. ${
              error.response && error.response.status === 423
                ? 'Este conteúdo já está associado a um curso!'
                : error.message
            }`,
          })
        }
      }
    })
  }, [])

  const contentsToBeShown = useMemo(() => {
    return perks && perks.length
      ? perks.map((perk) => ({
          content_id: perk.perk_id,
          description: perk.description,
          // active: perk.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <>
              <Button
                onClick={() => editPerk(perk.perk_id)}
                className="small info"
                title="Editar Conteúdo"
              >
                <BiEdit />
              </Button>{' '}
              {/* <Button
                onClick={() => toggleActivePerk(perk?.perk_id, !perk.is_active)}
                className="small warning"
                title={(perk.is_active ? 'Inativar' : 'Ativar') + ' Conteúdo'}
              >
                {perk.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </Button>{' '} */}
              <Button
                onClick={() => {
                  removePerk(perk.perk_id)
                }}
                className="small danger"
                title="Excluir Conteúdo"
              >
                <BiTrash />
              </Button>
            </>
          ),
        }))
      : []
  }, [perks, editPerk, removePerk, toggleActivePerk])

  useEffect(() => {
    getPerks()
  }, [])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <span key={2}>Conteúdos Landing Page</span>,
        ]}
      />

      <PageTitle>Conteúdos Landing Page</PageTitle>

      <Box padding="0 0 20px 0">
        <Button onClick={createPerk}>Criar Conteúdo Landing Page</Button>
      </Box>

      <Table
        headersConfig={[
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados conteúdos cadastrados!'}
      />
    </Container>
  )
}

export default Perks
