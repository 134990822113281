import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import Select from 'components/Select'

import Category from 'models/category'
import MediaSambaVideos from 'models/media'
import Plan from 'models/plan'

import generateThumbnail from 'helpers/generate-thumbnail'
import checkEmptyString from 'helpers/check-empty-string'
import { hideModal, showModal } from 'helpers/modal'
import {
  createContent,
  getContent as getContentService,
  updateContent as updateContentService,
} from 'services/contents'
import { getAllCategories } from 'services/categories'
import { getAllPlans } from 'services/plans'
import { uploadFile } from 'services/files'

import Form from 'components/Form'
import FormGroup from 'components/FormGroup'
import Input from 'components/Input'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import TextArea from 'components/TextArea'
import CutImage from 'components/CutImage'
import SelectSambaVideosContent from 'components/SelectSambaVideosContent'

import {
  Container,
  ContentUploadOrSelectContainer,
  ContentThumbnail,
  ThumbnailUploadContainer,
  FormButtons,
  ButtonGroupInline,
} from './style'

interface CreateAndEditVideoProps {
  contentId: string
}

const CreateAndEditCategory: React.FC = () => {
  const { contentId } = useParams<CreateAndEditVideoProps>()

  const history = useHistory()

  const SAMBA_PLAYER_URL = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${process.env.REACT_APP_PLAYER_KEY}`

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [reference, setReference] = useState('')
  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>()
  const [thumbnailLargeFileToUpload, setThumbnailLargeFileToUpload] = useState<File>()
  const [thumbnail, setThumbnail] = useState('')
  const [thumbnailLarge, setThumbnailLarge] = useState('')
  const [duration, setDuration] = useState(0)
  const [type, setType] = useState('')
  const [showLanding, setShowLanding] = useState(false)
  const [categories, setCategories] = useState<Category[]>()
  const [selectedCategory, setSelectedCategory] =
    useState<{ label: string; value: string; highlight?: boolean }[]>()
  const [plans, setPlans] = useState<Plan[]>()
  const [selectedPlans, setSelectedPlans] = useState<{ label: string; value: string }[]>()

  const createVideo = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o vídeo.')
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válida para o vídeo.')
      }

      if (checkEmptyString(reference)) {
        throw new Error('Selecione um conteúdo.')
      }

      if (!selectedCategory || !selectedCategory.length) {
        throw new Error('Selecione uma categoria.')
      }

      if (!thumbnailFileToUpload || !thumbnailLargeFileToUpload) {
        throw new Error('Selecione uma imagem de miniatura.')
      }

      const thumbnailReference = await getImageReference(thumbnailFileToUpload)
      const thumbnailLargeReference = await getImageReference(thumbnailLargeFileToUpload)

      const categoriesToCreate = selectedCategory.map((category) => ({
        category_id: category.value,
        highlight: category.highlight,
      }))

      const plansToCreate =
        selectedPlans &&
        selectedPlans.map((plan) => ({
          plan_id: plan.value,
        }))

      await createContent({
        title,
        description,
        reference,
        duration,
        type,
        categories: categoriesToCreate,
        thumbnail: thumbnailReference,
        info: {
          thumbnail_large: thumbnailLargeReference,
          landing: showLanding,
          plans: plansToCreate ? plansToCreate : [],
        },
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Vídeo criado com sucesso!',
        icon: 'success',
      })

      goToVideos()
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o vídeo. ' + error.message,
        icon: 'error',
      })
    }
  }

  const updateVideo = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para o vídeo.')
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válida para o vídeo.')
      }

      if (checkEmptyString(reference)) {
        throw new Error('Selecione um conteúdo.')
      }

      if (!selectedCategory || !selectedCategory.length) {
        throw new Error('Selecione uma categoria.')
      }

      if (!thumbnail && !thumbnailFileToUpload) {
        throw new Error('Selecione uma imagem de miniatura.')
      }

      const resolveThumbnail = async (): Promise<string> => {
        if (thumbnailFileToUpload) return await getImageReference(thumbnailFileToUpload)
        return thumbnail
      }

      const resolveThumbnailLarge = async (): Promise<string> => {
        if (thumbnailLargeFileToUpload) return await getImageReference(thumbnailLargeFileToUpload)
        return thumbnailLarge
      }

      const categoriesToUpdate = selectedCategory.map((category) => ({
        category_id: category.value,
        highlight: category.highlight,
      }))

      const plansToUpdate =
        selectedPlans &&
        selectedPlans.map((plan) => ({
          plan_id: plan.value,
        }))

      await updateContentService(contentId, {
        title,
        description,
        reference,
        duration,
        type,
        categories: categoriesToUpdate,
        thumbnail: await resolveThumbnail(),
        info: {
          thumbnail_large: await resolveThumbnailLarge(),
          landing: showLanding,
          plans: plansToUpdate ? plansToUpdate : [],
        },
      })

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo editado com sucesso!',
        icon: 'success',
      })

      goToVideos()
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o conteúdo. ' + error.message,
        icon: 'error',
      })
    }
  }

  const selectThumbnail = (): void => {
    showModal('Selecionar Imagem de Miniatura', <CutImage aspect={1.812} onCutImage={onCutImage} />)
  }

  const onCutImage = (file: File): void => {
    if (file) {
      generateThumbnail({
        file,
        maxWidth: 375,
        callback: (thumbnail) => setThumbnailFileToUpload(thumbnail),
      })
      generateThumbnail({
        file,
        maxWidth: 1200,
        callback: (thumbnail) => setThumbnailLargeFileToUpload(thumbnail),
      })

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => setThumbnail(`${reader.result}`)

      hideModal()
    }
  }

  const getImageReference = async (imageFile: File): Promise<string> => {
    try {
      return (await uploadFile(imageFile)).reference
    } catch {
      throw new Error(
        'Erro ao fazer upload da imagem de miniatura. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
      )
    }
  }

  const goToVideos = (): void => {
    history.push('/videos')
  }

  const onSelectContent = (sambaVideosContent: MediaSambaVideos): void => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0]

        if (firstFile) {
          setReference(sambaVideosContent.id)
          setDuration(firstFile.fileInfo.duration / 1000)
          setType(firstFile.qualifier)
        }
      }
    }

    hideModal()
  }

  const selectContent = (): void => {
    showModal('Selecionar Conteúdo', <SelectSambaVideosContent onSelectContent={onSelectContent} />)
  }

  const categoriesToBeSelected = useMemo(() => {
    if (categories && categories.length) {
      return categories.map((category) => ({
        label: category.title,
        value: `${category.category_id}`,
      }))
    }

    return [] as { label: string; value: string }[]
  }, [categories])

  const plansToBeSelected = useMemo(() => {
    if (plans && plans.length) {
      return plans.map((plan) => ({
        label: plan.title,
        value: `${plan.plan_id}`,
      }))
    }

    return [] as { label: string; value: string }[]
  }, [plans])

  const getContent = useCallback(async () => {
    const foundCategories = await getAllCategories()
    foundCategories && setCategories(foundCategories)

    const foundPlans = await getAllPlans()
    foundPlans && setPlans(foundPlans)

    if (contentId) {
      const content = await getContentService(contentId)
      if (content && Object.keys(content).length) {
        setTitle(content.title)
        setDescription(content.description)
        setThumbnail(content.thumbnail)
        setThumbnailLarge(content.info.thumbnail_large)
        setReference(content.reference)
        setType(content.type)
        setDuration(content.duration)
        setShowLanding(content.info.landing)

        if (content.categories) {
          const selectedCategories = content.categories.map((category: any) => ({
            label: category.title,
            value: category.id,
            highlight: category.highlight,
          }))
          setSelectedCategory(selectedCategories)
        }

        if (content.info.plans) {
          const selectedPlans = content.info.plans.map((plan: any) => ({
            label: plan.title,
            value: plan.plan_id,
          }))
          setSelectedPlans(selectedPlans)
        }
      }
    }
  }, [contentId])

  useEffect(() => {
    getContent()
  }, [getContent])

  const isEditting = useMemo(() => {
    if (contentId) {
      return true
    }

    return false
  }, [contentId])

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link key={1} to="/home">
            Início
          </Link>,
          <Link key={2} to="/videos">
            Videos
          </Link>,
          <span key={3}>{isEditting ? 'Editar' : 'Criar'} Vídeo</span>,
        ]}
      />

      <PageTitle>{isEditting ? 'Editar' : 'Criar'} Video</PageTitle>

      <Form>
        <FormGroup>
          <label htmlFor="title" className="required">
            Título
          </label>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} id="title" required />
        </FormGroup>

        <FormGroup>
          <label htmlFor="description" className="required">
            Descrição
          </label>
          <TextArea
            value={description || ''}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
            required
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="reference" className="required">
            Conteúdo
          </label>

          <ContentUploadOrSelectContainer>
            <ButtonGroupInline>
              <Button type="button" onClick={selectContent}>
                Selecionar Conteúdo
              </Button>
            </ButtonGroupInline>
            {reference ? (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={`${SAMBA_PLAYER_URL}/${reference}`}
                frameBorder={0}
              ></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </FormGroup>

        <FormGroup>
          <label htmlFor="tags" className="required">
            Categorias
          </label>
          <Select
            id="categories"
            options={categoriesToBeSelected}
            value={selectedCategory}
            onChange={(options: any) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedCategory(
                options.map((opt: any) => ({
                  label: opt.label,
                  value: opt.value,
                  highlight: false,
                }))
              )
            }
            isMulti
            placeholder="Selecione uma categoria para este conteúdo."
          />
        </FormGroup>

        {selectedCategory?.length &&
          selectedCategory.map((category) => (
            <FormGroup key={category.value} type="checkbox">
              <label htmlFor={category.label}>{`Destaque em ${category.label}`}</label>
              <Input
                id={category.label}
                type="checkbox"
                checked={category.highlight}
                onChange={(e) =>
                  setSelectedCategory(
                    selectedCategory.map((selectedC) =>
                      selectedC.value === category.value
                        ? { ...category, highlight: e.target.checked }
                        : selectedC
                    )
                  )
                }
              />
            </FormGroup>
          ))}

        <FormGroup>
          <label htmlFor="tags">Planos</label>
          <Select
            id="plans"
            options={plansToBeSelected}
            value={selectedPlans}
            onChange={(options: any) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedPlans(
                options.map((opt: any) => ({
                  label: opt.label,
                  value: opt.value,
                }))
              )
            }
            isMulti
            placeholder="Selecione planos para os quais este conteúdo estará disponível."
          />
        </FormGroup>

        <FormGroup type="checkbox">
          <label htmlFor="landing">Mostrar na Landing Page</label>
          <Input
            id="landing"
            type="checkbox"
            checked={showLanding}
            onChange={(e) => setShowLanding(e.target.checked)}
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="reference" className="required">
            Miniatura
          </label>

          <ThumbnailUploadContainer>
            <Button type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Miniatura
            </Button>

            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </FormGroup>

        <FormButtons>
          <Button type="button" className="danger" onClick={goToVideos}>
            Cancelar
          </Button>
          <Button
            onClick={(e) => (isEditting ? updateVideo(e) : createVideo(e))}
            className="success"
          >
            Salvar
          </Button>
        </FormButtons>
      </Form>
    </Container>
  )
}

export default CreateAndEditCategory
