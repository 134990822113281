import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  
  :root {
    --primary-color: #E09321;
    --secondary-color: #DAA520;
    --danger-color: #F5365C;
    --info-color: #11CDEF;
    --warning-color: #FB6340;
    --success-color: #2DCE89;
    --black: #1D1D1B;
    --white: #FFF;
    --gray: #878787;
    --purple: #31348B;
    --dark-purple: #131263;
    --blue: #1072B9;
    --alt-blue: #0173B5;
    --light-blue: #31ADE4;
    --button: #5075CD;
    --yellow: #FCC201;
    --green: #ACC32B;
    --red: #E52629;
    --input-form: rgba(155, 153, 175, 0.31);
    --neutral-light20: #F0F3F6;
    --neutral-light40: #D3D8DD;
    --neutral-mid400: #505F79;
    --neutral-dark900: #061938;
    --blue-gradient: radial-gradient(57.45% 101.51% at 50% 50%, #131263 0%, #0C0C33 100%);
    --purple-gradient: linear-gradient(180deg, #31348B 0%, #2D1B5A 100%);
    --gold-gradient: linear-gradient(180deg, rgba(70, 54, 21, 0.3) 11.29%, rgba(0, 0, 0, 0.4) 95.62%);
    --text: #444444;
    --default-title-color: #FFFFFF;
    --default-dark-gray: #ced4da;
    --default-gray: #8898AA;
    --border-hr: rgba(255, 255, 255, 0.15);
    
    --font-smallest: 12px;
    --font-small: 14px;
    --font-medium: 18px;
    --font-large: 26px;
    --font-larger: 32px;
  }

  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: 'Montserrat', sans-serif;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  body {
    background-color: var(--background);
    color: var(--text);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  label, button, input {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
  }

  ul {
    list-style: none;
  }

  & .swal2-popup {
    font-size: 1.75rem;
  }

  label.required::after {
    content: ' *';
    color: red;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  .spinner.small {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 35px;
    height: 35px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  #global-modal {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    z-index: 4;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
`

export default GlobalStyles
